.notification-cover{

    .public-notifications{
        height: 100px;
        background: url('../images/front-view-person-with-holy-book-rosary.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position-y: 38%;

        h4{
            padding: 60px 10px 10px 10px;
            cursor: pointer;
            bottom: 0 !important;
            font-size: 1.5rem;
            color: #fff;
            letter-spacing: 1px;
        }
    }

    .notifications{
        display: flex;
        flex-direction: row;
        
        justify-content: center;

        .centre{
            display: flex;
            flex-direction: row;
            width: 90%;
            .left{
                color: black;
                width: 20%;

                display: flex;
                flex-direction: column;
                padding: 5px;
                border-right: 1px solid #4f2500;

                button{
                    margin-bottom: 10px;
                    border: 1px solid #fff;
                    
                    .titles{
                        display: flex;
                        flex-direction: column;
                        text-align: start;
                        padding: 10px;
                        cursor: pointer;
                    }
                }

                button:hover{
                    background: #000 !important;
                    color: white !important;
                    transition: 1s;
                }

                button:focus{
                    background: #000 !important;
                    color: white !important;
                }
                

            }
    
            .right{
                color: #000;
                width: 80%;

                display: flex;
                flex-direction: column;
                padding: 5px;

                .whole-message{
                    h5{
                        border-bottom: 1px solid black;
                    }

                    h4{
                        margin-top: 10px;
                    }

                    p{
                        margin-top: 10px;
                        letter-spacing: 1px;
                    }
                }
            }

        }

        
    }

}

@media screen and (max-width:710px){

    .notification-cover{

        .public-notifications{
            height: 100px;
            background: url('../images/front-view-person-with-holy-book-rosary.jpg');
            background-repeat: no-repeat;
            background-size: cover;
            background-position-y: 38%;
    
            h4{
                padding: 60px 10px 10px 10px;
                cursor: pointer;
                bottom: 0 !important;
                font-size: 1.5rem;
                color: #fff;
                letter-spacing: 1px;
            }
        }
    
        .notifications{
            display: flex;
            flex-direction: row;
            
            justify-content: center;
    
            .centre{
                display: flex;
                flex-direction: row;
                width: 90%;
                .left{
                    color: black;
                    width: 20%;
    
                    display: flex;
                    flex-direction: column;
                    padding: 5px;
                    border-right: 1px solid #4f2500;
    
                    button{
                        margin-bottom: 10px;
                        border: 1px solid #fff;
                        font-size: .7em;
                        
                        .titles{
                            display: flex;
                            flex-direction: column;
                            text-align: start;
                            padding: 10px;
                            cursor: pointer;
                        }
                    }
    
                    button:hover{
                        background: #000 !important;
                        color: white !important;
                        transition: 1s;
                    }
    
                    button:focus{
                        background: #000 !important;
                        color: white !important;
                    }
                    
    
                }
        
                .right{
                    color: #000;
                    width: 80%;
    
                    display: flex;
                    flex-direction: column;
                    padding: 5px;
    
                    .whole-message{
                        h5{
                            border-bottom: 1px solid black;
                            font-size: .7em;
                        }
    
                        h4{
                            margin-top: 10px;
                            font-size: .8em;
                        }
    
                        p{
                            margin-top: 10px;
                            letter-spacing: 1px;
                            font-size: .7em;
                        }
                    }
                }
    
            }
    
            
        }
    
    }
}

@media screen and (max-width:710px){

    .notification-cover{

        .public-notifications{
            height: 100px;
            background: url('../images/front-view-person-with-holy-book-rosary.jpg');
            background-repeat: no-repeat;
            background-size: cover;
            background-position-y: 38%;
    
            h4{
                padding: 60px 10px 10px 10px;
                cursor: pointer;
                bottom: 0 !important;
                font-size: 1.5rem;
                color: #fff;
                letter-spacing: 1px;
            }
        }
    
        .notifications{
            display: flex;
            flex-direction: row;
            
            justify-content: center;
    
            .centre{
                display: flex;
                flex-direction: row;
                width: 90%;
                .left{
                    color: black;
                    width: 20%;
    
                    display: flex;
                    flex-direction: column;
                    padding: 2px;
                    border-right: 1px solid #4f2500;
    
                    button{
                        margin-bottom: 10px;
                        border: 1px solid #fff;
                        font-size: .6em;
                        
                        .titles{
                            display: flex;
                            flex-direction: column;
                            text-align: start;
                            padding: 5px;
                            font-size: .6em;
                            cursor: pointer;
                        }
                    }
    
                    button:hover{
                        background: #000 !important;
                        color: white !important;
                        transition: 1s;
                    }
    
                    button:focus{
                        background: #000 !important;
                        color: white !important;
                    }
                    
    
                }
        
                .right{
                    color: #000;
                    width: 80%;
    
                    display: flex;
                    flex-direction: column;
                    padding: 5px;
    
                    .whole-message{
                        h5{
                            border-bottom: 1px solid black;
                            font-size: .6em;
                        }
    
                        h4{
                            margin-top: 10px;
                            font-size: .7em;
                        }
    
                        p{
                            margin-top: 10px;
                            letter-spacing: 1px;
                            font-size: .6em;
                        }
                    }
                }
    
            }
    
            
        }
    
    }
}