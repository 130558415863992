.projects-cover{

    .image-top-projects{
        height: 100px;
        background: url('../images/tomatoes.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position-y: 38%;

        h4{
            padding: 60px 10px 10px 10px;
            cursor: pointer;
            bottom: 0 !important;
            font-size: 1.5rem;
            color: #fff;
            letter-spacing: 1px;
        }
    }

    .first-projects{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        padding: 50px 0;
        gap:40px 60px;

        .drop{
            position: relative;
            width: 350px;
            height: 350px;
            box-shadow: inset 20px 20px 20px rgba(0,0,0,0.05),
            25px 35px 20px rgba(0,0,0,0.05),
            25px 30px 30px rgba(0,0,0,0.05),
            inset -20px -20px 25px rgba(255,255,255,0.9);
            transition: 0.5s ease-in-out;
            cursor: pointer;
            color:#4f2500;

            .content{
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                text-align: center;
                padding: 40px;
                gap: 15px;

                h4{
                    position: relative;
                    width: 160px;
                    height: 90px;
                    background: #eff0f4;
                    border-radius: 50%;
                    box-shadow: inset 2px 5px 10px rgba(0,0,0,0.1),
                    inset -2px -5px 10px rgba(255,255,255,1),
                    15px 15px 10px rgba(0,0,0,0.05),
                    15px 10px 15px rgba(0,0,0,0.025);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 1.1rem;
                    color: var(--clr)
                }

                .navlink{
                    position: relative;
                    padding: 10px 25px;
                    background: var(--clr);
                    text-decoration: none;
                    color: #fff;
                    border-radius: 25px;
                    font-weight: 500;
                    text-shadow: 0 2px rgba(0,0,0,0.25);
                    opacity: 0.75;
                    transition: 0.5s;
                }

                .navlink:hover{
                    opacity:1;
                }
            }
        }

        .drop:nth-child(1){
            border-radius: 57% 43% 43% 57% / 43% 43% 57% 70%;
        }

        .drop:nth-child(2){
            border-radius: 61% 39% 52% 48% / 44% 59% 41% 56%;
        }

        .drop:nth-child(3){
            border-radius: 35% 65% 31% 69% / 57% 59% 41% 43%;
        }

        .drop:hover{
            border-radius: 50%;
        }

        .drop::before{
            content: '';
            position: absolute;
            top:50px;
            left:65px;  
            width: 35px;
            height: 35px;
            background: #4f2500;
            border-radius: 50%;
            opacity: 0.9;
        }

        .drop::after{
            content: '';
            position: absolute;
            top:90px;
            left:90px;  
            width: 15px;
            height: 15px;
            background: #e77a1b;
            border-radius: 50%;
            opacity: 0.9;
        }

        .row-images{
            
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            .cards{
                width: 90%;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;

                .first{
                    cursor: pointer;
                    box-shadow: 0 0 20px 2px rgba(0,0,0, .1);
                    transition: 1s;
                    img{
                        width: 300px;
                        height: 200px;
                    }
                }

                .first:hover{
                    transform: scale(1.8);
                    z-index: 1;
                }
            }
        }

    }

    .second-projects{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        padding: 10px 0;
        gap:40px 60px;

        .drop{
            position: relative;
            width: 350px;
            height: 350px;
            box-shadow: inset 20px 20px 20px rgba(0,0,0,0.05),
            25px 35px 20px rgba(0,0,0,0.05),
            25px 30px 30px rgba(0,0,0,0.05),
            inset -20px -20px 25px rgba(255,255,255,0.9);
            transition: 0.5s ease-in-out;
            cursor: pointer;
            color:#4f2500;

            .content{
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                text-align: center;
                padding: 40px;
                gap: 15px;

                h4{
                    position: relative;
                    width: 170px;
                    height: 120px;
                    background: #eff0f4;
                    border-radius: 50%;
                    box-shadow: inset 2px 5px 10px rgba(0,0,0,0.1),
                    inset -2px -5px 10px rgba(255,255,255,1),
                    15px 15px 10px rgba(0,0,0,0.05),
                    15px 10px 15px rgba(0,0,0,0.025);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 1.1rem;
                    color: var(--clr)
                }

                a{
                    position: relative;
                    padding: 10px 25px;
                    background: var(--clr);
                    text-decoration: none;
                    color: #fff;
                    border-radius: 25px;
                    font-weight: 500;
                    text-shadow: 0 2px rgba(0,0,0,0.25);
                    opacity: 0.75;
                    transition: 0.5s;
                }

                a:hover{
                    opacity:1;
                }

            }
        }

        .drop:nth-child(1){
            border-radius: 40% 60% 55% 45% / 51% 30% 70% 49%;
        }

        .drop:nth-child(2){
            border-radius: 36% 64% 35% 65% / 49% 34% 66% 51%;
        }

        .drop:nth-child(3){
            border-radius: 49% 51% 47% 53% / 64% 30% 70% 36%;
        }

        .drop:hover{
            border-radius: 50%;
        }

        .drop::before{
            content: '';
            position: absolute;
            top:50px;
            left:65px;  
            width: 35px;
            height: 35px;
            background: #4f2500;
            border-radius: 50%;
            opacity: 0.9;
        }

        .drop::after{
            content: '';
            position: absolute;
            top:90px;
            left:50px;  
            width: 15px;
            height: 15px;
            background: #e77a1b;
            border-radius: 50%;
            opacity: 0.9;
        }

        .row-images{
            
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            .cards{
                width: 90%;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;

                .first{
                    cursor: pointer;
                    box-shadow: 0 0 20px 2px rgba(0,0,0, .1);
                    transition: 1s;
                    img{
                        width: 300px;
                        height: 200px;
                    }
                }

                .first:hover{
                    transform: scale(1.8);
                    z-index: 1;
                }
            }
        }

    }

    .photo-gallery{
        display: flex;
        flex-direction: row;
        justify-content: center;
        

        .projects-images{
            width: 90%;
            display: flex;
            flex-direction: column;
            background-color: red;
            padding: 10px;

            .scroll-images{
                h4{
                    color: #fff;
                }

                @keyframes slidex {
                    0% { transform: translateX(0); }
                    100% { transform: translateX(-100vw); }
                }

                .scroll{
                    display: flex;
                    padding: 5px;
                    align-items: center;
                    justify-content: center;
                    animation: slidex 40s linear infinite;
                    width: 200vw;

                    .claroLogo, .sharpLogo, .arrowLogo, .planasLogo, .epsonLogo, .samsungLogo, .dingliLogo, .arrowLogo, 
                    .rotatrimLogo, .casioLogo, .deliLogo, .typekLogo, .hpLogo {
                        display: flex;
                        align-items: center;
                        display: flex;
                        //flex-shrink: 0;
                        justify-content: center;
                        //width: 200vw;
                        margin: 0 20px;

                        //background-color: grey;
                        padding: 10px;
                        border-radius: 15px;
                        box-shadow: 5px 0 15px #bfd9bf;

                        img{
                            // width: 50vmin;
                            // margin: 0 40px;
                            //-webkit-transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
                        }
                    }
                }
            }

            .gallery{
                h4{
                    color: #fff;
                }
            }
        }
    }

    
}