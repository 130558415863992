.visit-mutemwa-cover{

    .image-visit-mutemwa{
        height: 100px;
        background: url('../images/mountain.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position-y: 38%;

        h4{
            padding: 60px 10px 10px 10px;
            cursor: pointer;
            bottom: 0 !important;
            font-size: 1.5rem;
            color: #fff;
            letter-spacing: 1px;
        }
    }

    .visitor-cover{
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        padding: 10px;
        width: 90%;

        .drop{
            position: relative;
            width: 350px;
            height: 350px;
            box-shadow: inset 20px 20px 20px rgba(0,0,0,0.05),
            25px 35px 20px rgba(0,0,0,0.05),
            25px 30px 30px rgba(0,0,0,0.05),
            inset -20px -20px 25px rgba(255,255,255,0.9);
            transition: 0.5s ease-in-out;
            cursor: pointer;
            color:#4f2500;
            margin: 10px;

            .content{
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                text-align: center;
                padding: 40px;
                gap: 15px;

                h4{
                    position: relative;
                    width: 200px;
                    height: 120px;
                    background: #e77a1b;
                    border-radius: 50%;
                    box-shadow: inset 2px 5px 10px rgba(0,0,0,0.1),
                    inset -2px -5px 10px rgba(255,255,255,1),
                    15px 15px 10px rgba(0,0,0,0.05),
                    15px 10px 15px rgba(0,0,0,0.025);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 1.1rem;
                    color: var(--clr)
                }

                p{
                    display: flex;
                    justify-content: center;

                
                }

            }
        }

        .drop:nth-child(1){
            border-radius: 40% 60% 55% 45% / 51% 30% 70% 49%;
        }

        .drop:nth-child(2){
            border-radius: 36% 64% 35% 65% / 49% 34% 66% 51%;
        }

        .drop:nth-child(3){
            border-radius: 49% 51% 47% 53% / 64% 30% 70% 36%;
        }

        .drop:nth-child(4){
            border-radius: 49% 51% 47% 53% / 64% 30% 70% 36%;
        }

        .drop:nth-child(5){
            border-radius: 49% 51% 47% 53% / 64% 30% 70% 36%;
        }

        .drop:nth-child(6){
            border-radius: 49% 51% 47% 53% / 64% 30% 70% 36%;
        }

        .drop:hover{
            border-radius: 50%;
        }
        
    }

}

@media screen and (max-width:320px){
    .visit-mutemwa-cover{

        .image-visit-mutemwa{
            height: 100px;
            background: url('../images/mountain.jpg');
            background-repeat: no-repeat;
            background-size: cover;
            background-position-y: 38%;
    
            h4{
                padding: 60px 10px 10px 10px;
                cursor: pointer;
                bottom: 0 !important;
                font-size: .8rem;
                color: #fff;
                letter-spacing: 1px;
            }
        }
    
        .visitor-cover{
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: center;
            padding: 10px;
            width: 90%;
    
            .drop{
                position: relative;
                width: 350px;
                height: 350px;
                box-shadow: inset 20px 20px 20px rgba(0,0,0,0.05),
                25px 35px 20px rgba(0,0,0,0.05),
                25px 30px 30px rgba(0,0,0,0.05),
                inset -20px -20px 25px rgba(255,255,255,0.9);
                transition: 0.5s ease-in-out;
                cursor: pointer;
                color:#4f2500;
                margin: 10px;
    
                .content{
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    text-align: center;
                    padding: 40px;
                    gap: 15px;
    
                    h4{
                        position: relative;
                        width: 200px;
                        height: 120px;
                        background: #e77a1b;
                        border-radius: 50%;
                        box-shadow: inset 2px 5px 10px rgba(0,0,0,0.1),
                        inset -2px -5px 10px rgba(255,255,255,1),
                        15px 15px 10px rgba(0,0,0,0.05),
                        15px 10px 15px rgba(0,0,0,0.025);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 1.1rem;
                        color: var(--clr)
                    }
    
                    p{
                        display: flex;
                        justify-content: center;
    
                    
                    }
    
                }
            }
    
            .drop:nth-child(1){
                border-radius: 40% 60% 55% 45% / 51% 30% 70% 49%;
            }
    
            .drop:nth-child(2){
                border-radius: 36% 64% 35% 65% / 49% 34% 66% 51%;
            }
    
            .drop:nth-child(3){
                border-radius: 49% 51% 47% 53% / 64% 30% 70% 36%;
            }
    
            .drop:nth-child(4){
                border-radius: 49% 51% 47% 53% / 64% 30% 70% 36%;
            }

            .drop:nth-child(5){
                border-radius: 49% 51% 47% 53% / 64% 30% 70% 36%;
            }

            .drop:nth-child(6){
                border-radius: 49% 51% 47% 53% / 64% 30% 70% 36%;
            }
    
            .drop:hover{
                border-radius: 50%;
            }
            
        }
    
    }
}