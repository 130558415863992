.contactus-cover{

    .image-contactsus{
        height: 100px;
        background: url('../images/54995289.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position-y: 45%;

        h4{
            padding: 60px 10px 10px 10px;
            cursor: pointer;
            bottom: 0 !important;
            font-size: 1.5rem;
            color: #fff;
            letter-spacing: 1px;
        }
    }

}