@import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap');

*{
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Righteous', 'cursive', sans-serif,'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // color:#fff;
  // background: rgb(231,122,27);
  // background: linear-gradient(60deg, rgba(231,122,27,1) 0%, rgba(79,37,0,1) 25%, rgba(79,37,0,1) 50%, rgba(84,40,1,1) 75%, rgba(231,122,27,1) 100%);
  
}

ul{
  list-style-type:none;
}

a{
  text-decoration:none;
}

code {
  font-family: 'Righteous', cursive;
}
