.header{
    background: rgb(231,122,27);
    background: linear-gradient(90deg, rgba(231,122,27,1) 0%, rgba(255,255,255,1) 10%, rgba(79,37,0,1) 20%, rgba(79,37,0,1) 50%, rgba(79,37,0,1) 80%, rgba(220,211,204,1) 90%, rgba(231,122,27,1) 100%);
    background-repeat: no-repeat;
    color:#fff;
    margin: 55px 0 10px 0 ;
    box-shadow:0px 3px 10px 5px #e77a1b;
    height: 50px;
    z-index: 8;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    .logo{
        display: flex;
        align-items: center;
        img{
            height: 45px;
            width: 45px;
        }
        h4{
            font-size: 1.2rem;
            padding: 0 !important;
            margin: 0 0 0 6px !important;
            color: #fff;
        }
    }

    .nav-menu{
        cursor: pointer;
        display: flex;
        padding-left: 0 !important;
        margin-bottom: 0 !important;
        margin-top: 5px;
        justify-content: center;
            
        li {
            //background: white;
            display: flex;
            align-items: center;
            padding: 0;
            border-bottom: 2px solid transparent;
            margin: 5px;
            position: relative;
            
        }
            
        li a {
            display: flex;
            align-items: center;
            position: relative;
            color: #fff;
            font-size:1rem;
            font-weight: 500;
            padding: 0.4em 1em 0.4em 1em;
                        
        }

        li a:nth-child(4){
            padding-left: 0 !important;
        }
            
        li:hover, .active {
            background-color: #e77a1b;
            border-bottom: 2px solid #fff;
            transition: 1s;
        }
            
        .active{
            color: #fff;
        }
            
            
        li span{
            display: block;
            height:0.001em;
            width: 100%;
            position:absolute;
            bottom: 0;
            transition: 0.3s height ease;
        }
            
                    li:hover span{
                        height:100%;
                
                    }
            
                    li:hover{
                        a{
                            color: white;
                        }
                    }
            
                    li:hover:after{
                        content: "";
                        display:block;
                        width:100%;
                        height:0px;
                        position: absolute;
                        bottom: 0px;
                    }
                    
                    
    }
            
    .hamburger{
        display: none;
        font-size: 35px;
        height: fit-content;
        cursor: pointer;
        padding: 3px 8px;
        border-radius: 5px;
        transition: 0.2s;
        color: #4f2500;
    }
            
    .hamburger:hover{
        background: antiquewhite;
    }
            
    .hamburger div{
        width: 30px;
        height: 2px;
        margin-right: 6px 0;
        background: #212526;
    }
}

@media screen and (max-width:1000px){
    .header{
        background: rgb(231,122,27);
        background: linear-gradient(90deg, rgba(231,122,27,1) 0%, rgba(255,255,255,1) 10%, rgba(79,37,0,1) 20%, rgba(79,37,0,1) 50%, rgba(79,37,0,1) 80%, rgba(220,211,204,1) 90%, rgba(231,122,27,1) 100%);
        background-repeat: no-repeat;
        color:#fff;
        box-shadow:0px 3px 10px 5px #e77a1b;
        margin: 55px 0 10px 0 ;
        height: 50px;
        z-index: 8;
        display: flex;
        justify-content: space-evenly;
        align-items: center;

        .logo{
            display: flex;
            align-items: center;
            img{
                height: 45px;
                width: 45px;
            }
            h4{
                font-size: .8rem;
                padding: 0 !important;
                margin: 0 0 0 6px !important;
                color: #fff;
            }
        }
    
        .nav-menu{
            cursor: pointer;
            display: flex;
            padding-left: 0 !important;
            margin-bottom: 0 !important;
            margin-top: 0;
            justify-content: center;
                
            li {
                //background: white;
                padding: 0;
                border-bottom: 2px solid transparent;
                margin: 5px;
                position: relative;
            }
                
            li a {
                display: flex;
                align-items: center;
                position: relative;
                color: #fff;
                font-size:.8rem;
                font-weight: 500;
                padding: 0.4em 1em 0.4em 1em;
                            
            }
                
            li:hover, .active {
                background-color: #e77a1b;
                border-bottom: 2px solid #fff;
                transition: 1s;
            }
                
            .active{
                color: #fff;
            }
                
                
            li span{
                display: block;
                height:0.001em;
                width: 100%;
                position:absolute;
                bottom: 0;
                transition: 0.3s height ease;
            }
            
                
                        li:hover span{
                            height:100%;
                    
                        }
                
                        li:hover{
                            a{
                                color: white;
                            }
                        }
                
                        li:hover:after{
                            content: "";
                            display:block;
                            width:100%;
                            height:0px;
                            position: absolute;
                            bottom: 0px;
                        }
                        
                        
        }
                
        .hamburger{
                        display: none;
                        font-size: 35px;
                        height: fit-content;
                        cursor: pointer;
                        padding: 3px 8px;
                        border-radius: 5px;
                        transition: 0.2s;
                        color: #4f2500;
        }
                
        .hamburger:hover{
            background: antiquewhite;
        }
                
        .hamburger div{
            width: 30px;
            height: 2px;
            margin-right: 6px 0;
            background: #212526;
        }
    }
}

@media screen and (max-width:800px){
    .header{
        background: rgb(231,122,27);
        background: linear-gradient(90deg, rgba(231,122,27,1) 0%, rgba(255,255,255,1) 10%, rgba(79,37,0,1) 20%, rgba(79,37,0,1) 50%, rgba(79,37,0,1) 80%, rgba(220,211,204,1) 90%, rgba(231,122,27,1) 100%);
        background-repeat: no-repeat;
        color:#fff;
        box-shadow:0px 3px 10px 5px #e77a1b;
        margin: 55px 0 10px 0 ;
        height: 50px;
        z-index: 8;
        display: flex;
        justify-content: space-evenly;
        align-items: center;

        .logo{
            display: flex;
            align-items: center;
            img{
                height: 45px;
                width: 45px;
            }
            h4{
                font-size: .8rem;
                padding: 0 !important;
                margin: 0 0 0 6px !important;
                color: #fff;
            }
        }
    
        .nav-menu{
            cursor: pointer;
            display: flex;
            padding-left: 0 !important;
            margin-bottom: 0 !important;
            margin-top: 0;
            justify-content: center;
                
            li {
                //background: white;
                padding: 0;
                border-bottom: 2px solid transparent;
                margin: 5px;
                position: relative;
            }
                
            li a {
                display: flex;
                align-items: center;
                position: relative;
                color: #fff;
                font-size:.7rem;
                font-weight: 500;
                padding: 0.4em 1em 0.4em 1em;
                            
            }
                
            li:hover, .active {
                background-color: #e77a1b;
                border-bottom: 2px solid #fff;
                transition: 1s;
            }
                
            .active{
                color: #fff;
            }
                
                
            li span{
                display: block;
                height:0.001em;
                width: 100%;
                position:absolute;
                bottom: 0;
                transition: 0.3s height ease;
            }
            
                
                        li:hover span{
                            height:100%;
                    
                        }
                
                        li:hover{
                            a{
                                color: white;
                            }
                        }
                
                        li:hover:after{
                            content: "";
                            display:block;
                            width:100%;
                            height:0px;
                            position: absolute;
                            bottom: 0px;
                        }
                        
                        
        }
                
        .hamburger{
                        display: none;
                        font-size: 35px;
                        height: fit-content;
                        cursor: pointer;
                        padding: 3px 8px;
                        border-radius: 5px;
                        transition: 0.2s;
                        color: #4f2500;
        }
                
        .hamburger:hover{
            background: antiquewhite;
        }
                
        .hamburger div{
            width: 30px;
            height: 2px;
            margin-right: 6px 0;
            background: #212526;
        }
    }   
}

@media screen and (max-width:720px){
    .header{
        margin: 55px 0 10px 0 ;
        .logo{
            display: flex;
            align-items: center;
            img{
                height: 45px;
                width: 45px;
            }
            h4{
                font-size: .9rem;
                padding: 0 !important;
                margin: 0 0 0 6px !important;
                color: #fff;
            }
        }
    
        .nav-menu{
            background: rgb(231,122,27);
            background: linear-gradient(90deg, rgba(231,122,27,1) 0%, rgba(255,255,255,1) 10%, rgba(79,37,0,1) 20%, rgba(79,37,0,1) 50%, rgba(79,37,0,1) 80%, rgba(220,211,204,1) 90%, rgba(231,122,27,1) 100%);
            background-repeat: no-repeat;
            position: absolute;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 110px;
            left: 0;
            top: 0;
            z-index: 999;
            width: 100%;
            height: 250px;
            
            transition: 0.2s;
            box-shadow: 2px 0 20px #4f2500;
                
            li {
                //background: white;
                padding: 0;
                border-bottom: 2px solid transparent;
                margin: 5px;
                position: relative;
            }
                
            li a {
                display: flex;
                align-items: center;
                position: relative;
                color: #fff;
                font-size:.7rem;
                font-weight: 500;
                padding: 0.4em 1em 0.4em 1em;
                            
            }
                
            li:hover, .active {
                background-color: #e77a1b;
                border-bottom: 2px solid #fff;
                transition: 1s;
            }
                
            .active{
                color: #fff;
            }
                
                
            li span{
                display: block;
                height:0.001em;
                width: 100%;
                position:absolute;
                bottom: 0;
                transition: 0.3s height ease;
            }
            
                
                        li:hover span{
                            height:100%;
                    
                        }
                
                        li:hover{
                            a{
                                color: white;
                            }
                        }
                
                        li:hover:after{
                            content: "";
                            display:block;
                            width:100%;
                            height:0px;
                            position: absolute;
                            bottom: 0px;
                        }
                        
                        
        }
                
        .hamburger{
            display: block;
        }

        .hidden{
            display:none
        }

        .visible{
            display: inherit;
            transition: 2ms;
        }
    }
}

@media screen and (max-width:690px){
    .header{
        margin: 55px 0 10px 0 ;
        .logo{
            display: flex;
            align-items: center;
            img{
                height: 45px;
                width: 45px;
            }
            h4{
                font-size: .9rem;
                padding: 0 !important;
                margin: 0 0 0 6px !important;
                color: #fff;
            }
        }
    
        .nav-menu{
            background: rgb(231,122,27);
            background: linear-gradient(90deg, rgba(231,122,27,1) 0%, rgba(255,255,255,1) 10%, rgba(79,37,0,1) 20%, rgba(79,37,0,1) 50%, rgba(79,37,0,1) 80%, rgba(220,211,204,1) 90%, rgba(231,122,27,1) 100%);
            background-repeat: no-repeat;
            position: absolute;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 110px;
            left: 0;
            top: 0;
            z-index: 999;
            width: 100%;
            height: 250px;
            
            transition: 0.2s;
            box-shadow: 2px 0 20px #4f2500;
                
            li {
                //background: white;
                padding: 0;
                border-bottom: 2px solid transparent;
                margin: 5px;
                position: relative;
            }
                
            li a {
                display: flex;
            align-items: center;
                position: relative;
                color: #fff;
                font-size:.7rem;
                font-weight: 500;
                padding: 0.4em 1em 0.4em 1em;
                            
            }
                
            li:hover, .active {
                background-color: #e77a1b;
                border-bottom: 2px solid #fff;
                transition: 1s;
            }
                
            .active{
                color: #fff;
            }
                
                
            li span{
                display: block;
                height:0.001em;
                width: 100%;
                position:absolute;
                bottom: 0;
                transition: 0.3s height ease;
            }
            
                
                        li:hover span{
                            height:100%;
                    
                        }
                
                        li:hover{
                            a{
                                color: white;
                            }
                        }
                
                        li:hover:after{
                            content: "";
                            display:block;
                            width:100%;
                            height:0px;
                            position: absolute;
                            bottom: 0px;
                        }
                        
                        
        }
                
        .hamburger{
            display: block;
        }

        .hidden{
            display:none
        }

        .visible{
            display: inherit;
            transition: 2ms;
        }
    }
}

@media screen and (max-width:600px){
    .header{
        margin: 75px 0 10px 0 ;
        .logo{
            display: flex;
            align-items: center;
            img{
                height: 45px;
                width: 45px;
            }
            h4{
                font-size: .9rem;
                padding: 0 !important;
                margin: 0 0 0 6px !important;
                color: #fff;
            }
        }
    
        .nav-menu{
            background: rgb(231,122,27);
            background: linear-gradient(90deg, rgba(231,122,27,1) 0%, rgba(255,255,255,1) 10%, rgba(79,37,0,1) 20%, rgba(79,37,0,1) 50%, rgba(79,37,0,1) 80%, rgba(220,211,204,1) 90%, rgba(231,122,27,1) 100%);
            background-repeat: no-repeat;
            position: absolute;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 110px;
            left: 0;
            top: 0;
            z-index: 999;
            width: 100%;
            height: 250px;
            
            transition: 0.2s;
            box-shadow: 2px 0 20px #4f2500;
                
            li {
                //background: white;
                padding: 0;
                border-bottom: 2px solid transparent;
                margin: 5px;
                position: relative;
            }
                
            li a {
                display: flex;
            align-items: center;
                position: relative;
                color: #fff;
                font-size:.7rem;
                font-weight: 500;
                padding: 0.4em 1em 0.4em 1em;
                            
            }
                
            li:hover, .active {
                background-color: #e77a1b;
                border-bottom: 2px solid #fff;
                transition: 1s;
            }
                
            .active{
                color: #fff;
            }
                
                
            li span{
                display: block;
                height:0.001em;
                width: 100%;
                position:absolute;
                bottom: 0;
                transition: 0.3s height ease;
            }
            
                
                        li:hover span{
                            height:100%;
                    
                        }
                
                        li:hover{
                            a{
                                color: white;
                            }
                        }
                
                        li:hover:after{
                            content: "";
                            display:block;
                            width:100%;
                            height:0px;
                            position: absolute;
                            bottom: 0px;
                        }
                        
                        
        }
                
        .hamburger{
            display: block;
        }

        .hidden{
            display:none
        }

        .visible{
            display: inherit;
            transition: 2ms;
        }
    }
}

@media screen and (max-width:500px){

    .header{

        margin: 70px 0 10px 0 ;

        .logo{
            display: flex;
            align-items: center;
            img{
                height: 45px;
                width: 45px;
            }
            h4{
                font-size: .9rem;
                padding: 0 !important;
                margin: 0 0 0 6px !important;
                color: #fff;
            }
        }
    
        .nav-menu{
            background: rgb(231,122,27);
            background: linear-gradient(90deg, rgba(231,122,27,1) 0%, rgba(255,255,255,1) 10%, rgba(79,37,0,1) 20%, rgba(79,37,0,1) 50%, rgba(79,37,0,1) 80%, rgba(220,211,204,1) 90%, rgba(231,122,27,1) 100%);
            background-repeat: no-repeat;
            position: absolute;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 125px;
            left: 0;
            top: 0;
            z-index: 999;
            width: 100%;
            height: 250px;
            
            transition: 0.2s;
            box-shadow: 2px 0 20px #4f2500;
                
            li {
                //background: white;
                padding: 0;
                border-bottom: 2px solid transparent;
                margin: 5px;
                position: relative;
            }
                
            li a {
                display: flex;
            align-items: center;
                position: relative;
                color: #fff;
                font-size:.7rem;
                font-weight: 500;
                padding: 0.4em 1em 0.4em 1em;
                            
            }
                
            li:hover, .active {
                background-color: #e77a1b;
                border-bottom: 2px solid #fff;
                transition: 1s;
            }
                
            .active{
                color: #fff;
            }
                
                
            li span{
                display: block;
                height:0.001em;
                width: 100%;
                position:absolute;
                bottom: 0;
                transition: 0.3s height ease;
            }
            
                
                        li:hover span{
                            height:100%;
                    
                        }
                
                        li:hover{
                            a{
                                color: white;
                            }
                        }
                
                        li:hover:after{
                            content: "";
                            display:block;
                            width:100%;
                            height:0px;
                            position: absolute;
                            bottom: 0px;
                        }
                        
                        
        }
                
        .hamburger{
            display: block;
        }

        .hidden{
            display:none
        }

        .visible{
            display: inherit;
            transition: 2ms;
        }
    }
}

@media screen and (max-width:400px){

    .header{

        margin: 70px 0 10px 0 ;

        .logo{
            display: flex;
            align-items: center;
            img{
                height: 45px;
                width: 45px;
            }
            h4{
                font-size: .9rem;
                padding: 0 !important;
                margin: 0 0 0 6px !important;
                color: #fff;
            }
        }
    
        .nav-menu{
            background: rgb(231,122,27);
            background: linear-gradient(90deg, rgba(231,122,27,1) 0%, rgba(255,255,255,1) 10%, rgba(79,37,0,1) 20%, rgba(79,37,0,1) 50%, rgba(79,37,0,1) 80%, rgba(220,211,204,1) 90%, rgba(231,122,27,1) 100%);
            background-repeat: no-repeat;
            position: absolute;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 125px;
            left: 0;
            top: 0;
            z-index: 999;
            width: 100%;
            height: 250px;
            
            transition: 0.2s;
            box-shadow: 2px 0 20px #4f2500;
                
            li {
                //background: white;
                padding: 0;
                border-bottom: 2px solid transparent;
                margin: 5px;
                position: relative;
            }
                
            li a {
                display: flex;
            align-items: center;
                position: relative;
                color: #fff;
                font-size:.7rem;
                font-weight: 500;
                padding: 0.4em 1em 0.4em 1em;
                            
            }
                
            li:hover, .active {
                background-color: #e77a1b;
                border-bottom: 2px solid #fff;
                transition: 1s;
            }
                
            .active{
                color: #fff;
            }
                
                
            li span{
                display: block;
                height:0.001em;
                width: 100%;
                position:absolute;
                bottom: 0;
                transition: 0.3s height ease;
            }
            
                
                        li:hover span{
                            height:100%;
                    
                        }
                
                        li:hover{
                            a{
                                color: white;
                            }
                        }
                
                        li:hover:after{
                            content: "";
                            display:block;
                            width:100%;
                            height:0px;
                            position: absolute;
                            bottom: 0px;
                        }
                        
                        
        }
                
        .hamburger{
            display: block;
        }

        .hidden{
            display:none
        }

        .visible{
            display: inherit;
            transition: 2ms;
        }
    }
}

@media screen and (max-width:367px){

    .header{

        margin: 70px 0 10px 0 ;

        .logo{
            display: flex;
            align-items: center;
            img{
                height: 45px;
                width: 45px;
            }
            h4{
                font-size: .9rem;
                padding: 0 !important;
                margin: 0 0 0 6px !important;
                color: #fff;
            }
        }
    
        .nav-menu{
            background: rgb(231,122,27);
            background: linear-gradient(90deg, rgba(231,122,27,1) 0%, rgba(255,255,255,1) 10%, rgba(79,37,0,1) 20%, rgba(79,37,0,1) 50%, rgba(79,37,0,1) 80%, rgba(220,211,204,1) 90%, rgba(231,122,27,1) 100%);
            background-repeat: no-repeat;
            position: absolute;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 120px;
            left: 0;
            top: 0;
            z-index: 999;
            width: 100%;
            height: 250px;
            
            transition: 0.2s;
            box-shadow: 2px 0 20px #4f2500;
                
            li {
                //background: white;
                padding: 0;
                border-bottom: 2px solid transparent;
                margin: 5px;
                position: relative;
            }
                
            li a {
                display: flex;
            align-items: center;
                position: relative;
                color: #fff;
                font-size:.7rem;
                font-weight: 500;
                padding: 0.4em 1em 0.4em 1em;
                            
            }
                
            li:hover, .active {
                background-color: #e77a1b;
                border-bottom: 2px solid #fff;
                transition: 1s;
            }
                
            .active{
                color: #fff;
            }
                
                
            li span{
                display: block;
                height:0.001em;
                width: 100%;
                position:absolute;
                bottom: 0;
                transition: 0.3s height ease;
            }
            
                
                        li:hover span{
                            height:100%;
                    
                        }
                
                        li:hover{
                            a{
                                color: white;
                            }
                        }
                
                        li:hover:after{
                            content: "";
                            display:block;
                            width:100%;
                            height:0px;
                            position: absolute;
                            bottom: 0px;
                        }
                        
                        
        }
                
        .hamburger{
            display: block;
        }

        .hidden{
            display:none
        }

        .visible{
            display: inherit;
            transition: 2ms;
        }
    }
}

@media screen and (max-width:320px){
    
}