.home-cover{
    //background: rgb(79,37,0);
    //background: linear-gradient(90deg, rgba(79,37,0,1) 0%, rgba(255,255,255,1) 10%, rgba(79,37,0,1) 20%, rgba(79,37,0,1) 35%, rgba(79,37,0,1) 50%, rgba(79,37,0,1) 65%, rgba(79,37,0,1) 80%, rgba(220,211,204,1) 90%, rgba(79,37,0,1) 100%);
    background: rgb(237,238,235);
    background: linear-gradient(90deg, rgba(237,238,235,1) 17%, rgba(237,238,235,1) 44%, rgba(237,238,235,1) 66%, rgba(237,238,235,1) 100%);
    background-repeat: no-repeat;
    color: #4f2500;
    overflow: hidden;
    //padding: 10px;

    .banner1, .banner2, .banner3, .banner5{
        height: 100vh;
        width: 100%;
        position: absolute;
        left: 0;
        right: 0;
        z-index: 1;

        img{
            position: absolute;
            width: 100%;

        }

        .text-description{
            width: 600px;
            position: absolute;
            top: 200px;
            left: 30px;
            animation: textup 12s linear infinite;
            transform: translateY(100px);

            h1{
                margin-bottom: 5px;
            }

            span{
                position: absolute;
                height: 2px;
                left: 0;
                background-color: #4f2500;
                width: 100px;
            }

            p{
                font-size: 14px;
                line-height: 18px;
                margin-top: 20px;
            }

            .btn-register{
                margin-top: 5px;
                padding: 5px;
                font-size: 13px;
                background-color: #4f2500;
                color: white;

                a{
                    list-style: none;
                    color: #c7bcb3;
                }
            }

            .btn-register:hover{
                background-color: #fff;
                color: #000;
                transition: 2s;

                a{
                    list-style: none;
                    color: #000;
                }
            }
             
        }
    }

    .banner4{
        height: 300px;
        width: 500px;
        position: absolute;
        left: 0;
        right: 0;
        z-index: 2;

        img{
            position: absolute;
            height: 500px;
            width: 100%;
            left: 90%;
        }

        .text-description{
            width: 600px;
            position: absolute;
            top: 200px;
            left: 30px;
            animation: textup 12s linear infinite;
            transform: translateY(100px);

            h1{
                margin-bottom: 5px;
            }

            span{
                position: absolute;
                height: 2px;
                left: 0;
                background-color: #4f2500;
                width: 100px;
            }

            p{
                font-size: 14px;
                line-height: 18px;
                margin-top: 20px;
            }

            .btn-register{
                margin-top: 5px;
                padding: 5px;
                font-size: 13px;
                background-color: #4f2500;
                color: white;

                a{
                    list-style: none;
                    color: #c7bcb3;
                }
            }

            .btn-register:hover{
                background-color: #fff;
                color: #000;
                transition: 2s;

                a{
                    list-style: none;
                    color: #000;
                }
            }
             
        }
    }

    @keyframes textup{
        10%{
            transform: translateY(0px);
        }
        100%{
            transform: translateY(0px);
        }

    }

    .text-box1{
        animation-delay: 0s;
    }

    .text-box2{
        animation-delay: 3s;
    }

    .text-box3{
        animation-delay: 6s;
    }

    .text-box4{
        animation-delay: 9s;
    }

    .text-box5{
        animation-delay: 12s;
    }

    .banner1{
        animation: slide1 12s linear infinite;
    }

    .banner2{
        animation: slide2 12s linear infinite;
    }

    .banner3{
        animation: slide3 16s linear infinite;
    }

    .banner4{
        animation: slide4 12s linear infinite;
    }

    .banner5{
        animation: slide5 12s linear infinite;
    }

    @keyframes slide1{
        0%{
            visibility:visible;
        }
        20%{
            visibility: hidden;
        }
        30%{
            visibility: hidden;
        }
        50%{
            visibility: hidden;
        }
        65%{
            visibility: hidden;
        }
        80%{
            visibility: hidden;
        }
        100%{
            visibility: visible;
        }
    }

    @keyframes slide2{
        0%{
            visibility:hidden;
        }
        20%{
            visibility: hidden;
        }
        30%{
            visibility: visible;
        }
        50%{
            visibility: hidden;
        }
        65%{
            visibility: hidden;
        }
        80%{
            visibility: hidden;
        }
        100%{
            visibility: hidden;
        }
    }

    @keyframes slide3{
        0%{
            visibility:hidden;
        }
        20%{
            visibility: hidden;
        }
        30%{
            visibility: hidden;
        }
        50%{
            visibility: visible;
        }
        65%{
            visibility: hidden;
        }
        80%{
            visibility: hidden;
        }
        100%{
            visibility: hidden;
        }
    }

    @keyframes slide4{
        0%{
            visibility:hidden;
        }
        20%{
            visibility: hidden;
        }
        30%{
            visibility: hidden;
        }
        50%{
            visibility: hidden;
        }
        65%{
            visibility: visible;
        }
        80%{
            visibility: hidden;
        }
        100%{
            visibility: hidden;
        }
    }

    @keyframes slide5{
        0%{
            visibility:hidden;
        }
        20%{
            visibility: hidden;
        }
        30%{
            visibility: hidden;
        }
        50%{
            visibility: hidden;
        }
        65%{
            visibility: hidden;
        }
        80%{
            visibility: hidden;
        }
        100%{
            visibility: visible;
        }
    }

    .landing{
        display: flex;
        flex-direction: column;
        position: relative;
        margin-top: 550px;

        .title{
            padding: 10px;
            position: relative;
            h4{
                font-size: 2rem;
                margin-top: 20px;
                color: #e77a1b;
            }

            span{
                position: absolute;
                height: 2px;
                left: 10;
                background-color: #e77a1b;
                width: 100px;
                margin-top: 10px;
            }
                
        }

        .welcome{
            display: flex;
            justify-content: center;
            
            .option{
                overflow: hidden;
            }

            .option li{
                text-align: center;
                font-size: 1.1rem !important;
                letter-spacing: 1px;
                position: relative;
                top: 0;
            }     

            @keyframes animate{
                40%, 60%{
                    left:100%;
                }

                100%{
                    left:100%;
                }
            }
        }

        .d-design{
            height: 100vh;
            // background: url('../images/johnbradburn.jpg');
            // background-size: cover;
            // background-repeat: no-repeat;
            display: flex;
            justify-content: center;
            position: relative;

            .invitation-cover{
                position: absolute;
                    margin-top: 20px;
                    display: flex;
                    flex-direction: column;
                    justify-content: start;
                    left: 0;
                    margin-left: 10px;
                    height: 500px;
                    width: 220px;
                    box-shadow:0px 3px 10px 5px #c7bcb3;

                .invitation{
                    padding: 10px;
                    overflow: scroll;

                    .message{
                        border-bottom: 1px solid white;
                        p{
                            color: white !important;
                            
                            padding: 5px;
                        }
    
                    }
    
                    .message:hover{
                        cursor: pointer;
                        background: #4f2500;
                        color: white !important;
                        transition: 1s;
                    }
                }
                

                h4{
                    text-transform: uppercase;
                }

                

                .btn-viewMessage{
                    margin-top: 10px;
                    width: 100%;
                    padding: 10px 5px;
                    background-color: #4f2500;
                    cursor: pointer;
                    border-radius: 5px;
                    border: 1px solid transparent;
                }

                button a{
                    margin-top: 10px;
                    width: 160px;
                    padding: 10px 5px;
                    //background-color: #4f2500;
                    color: #fff !important;
                    cursor: pointer;
                    border-radius: 5px;     
                }

                .btn-viewMessage:hover{
                    border: 1px solid #4f2500;
                    background-color: white;
                    transition: 1s;

                    a{
                        color: #4f2500 !important;
                    }
                }
            }

            
            .brad-background{
                //width: 60%;
                height: 100vh;
                // background: url('../images/johnbradburn.jpg');
                // background-size: cover;
                // background-repeat: no-repeat;
                background-position-y: 20%;

                display: flex;
                justify-content: center;
                align-items: end;
                flex-wrap: wrap;
                padding: 10px 0;
                gap:40px 60px;

                .drop{
                    position: relative;
                    width: 500px;
                    height: 500px;
                    box-shadow: inset 20px 20px 20px rgba(0,0,0,0.05),
                    25px 35px 20px rgba(0,0,0,0.05),
                    25px 30px 30px rgba(0,0,0,0.05),
                    inset -20px -20px 25px rgba(255,255,255,0.9);
                    transition: 0.5s ease-in-out;
                    cursor: pointer;
                    color:#4f2500;
        
                    .content{
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        text-align: center;
                        padding: 40px;
                        gap: 15px;
        
                        h4{
                            position: relative;
                            width: 200px;
                            height: 120px;
                            background: #eff0f4;
                            border-radius: 50%;
                            box-shadow: inset 2px 5px 10px rgba(0,0,0,0.1),
                            inset -2px -5px 10px rgba(255,255,255,1),
                            15px 15px 10px rgba(0,0,0,0.05),
                            15px 10px 15px rgba(0,0,0,0.025);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-size: 1.1rem;
                            color: var(--clr)
                        }
    
                        p{
                            display: flex;
                            justify-content: center;
    
                            
            
                            span{
                                background: #4f2500;
                            border-radius: 25px;
                            padding: 5px;
                            font-size: 1.2rem;
                            color: white;
                            }
            
                            span:before{
                                content: '';
                                animation: anim infinite 30s;
                            }
            
                            @keyframes anim{
                                0%{
                                    content: 'Mutemwa Leprosy Settlement was established in 1937 as a Leprosium.';
                                }
                                25%{
                                    content: 'In 1969, John Randal Bradburne (14 June 1921 - 05 September 1979) became the warden of the settlement.';
                                }
                                50%{
                                    content: 'He cares for the sick in the house.';
                                }
                                75%{
                                    content: 'Currently, Mutemwa Leprosy and Care Centre hold an average of 32 residents.';
                                }
                                100%{
                                    content: 'Mutemwa Settlement is in Mutoko a small town in Mashonaland East Province of Zimbabwe. It lies 143km from Harare.';
                                }
                            }
                        }
        
                    }
                }
        
                .drop:nth-child(1){
                    border-radius: 75% 25% 44% 56% / 56% 32% 68% 44%;
                }
            }
            
        }

        .vision{
            height: 100%;
            // background: url('../images/mountainCross-01.png');
            // background-size: cover;
            // background-repeat: no-repeat;
            background-position-y: 17%;

            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            padding: 10px 0;
            gap:40px 60px;

            .drop{
                position: relative;
                width: 350px;
                height: 450px;
                background: #eff0f4;
                box-shadow: inset 20px 20px 20px rgba(0,0,0,0.05),
                25px 35px 20px rgba(0,0,0,0.05),
                25px 30px 30px rgba(0,0,0,0.05),
                inset -20px -20px 25px rgb(255,255,255);
                transition: 0.5s ease-in-out;
                cursor: pointer;
                color:#4f2500;
    
                .content{
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    text-align: center;
                    padding: 40px;
                    gap: 15px;

                    .svg{
                        img{
                            width: 50px;
                            height: 50px;
                        }
                    }
    
                    h4{
                        position: relative;
                        width: 200px;
                        height: 120px;
                        background: #eff0f4;
                        border-radius: 50%;
                        box-shadow: inset 2px 5px 10px rgba(0,0,0,0.1),
                        inset -2px -5px 10px rgba(255,255,255,0.1),
                        15px 15px 10px rgba(0,0,0,0.05),
                        15px 10px 15px rgba(0,0,0,0.025);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 1.1rem;
                        color: var(--clr)
                    }

                    p{
                        display: flex;
                        justify-content: center;

                    
                    }
    
                }
            }

            .drop:nth-child(3){
                position: relative;
                width: 350px;
                height: 650px;
                background: #eff0f4;
                box-shadow: inset 20px 20px 20px rgba(0,0,0,0.1),
                25px 35px 20px rgba(0,0,0,0.05),
                25px 30px 30px rgba(0,0,0,0.05),
                inset -20px -20px 25px rgba(255,255,255,1);
                transition: 0.5s ease-in-out;
                cursor: pointer;
                color:#4f2500;
    
                .content{
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    text-align: center;
                    padding: 40px;
                    gap: 15px;
    
                    h4{
                        position: relative;
                        width: 200px;
                        height: 120px;
                        background: #eff0f4;
                        border-radius: 50%;
                        box-shadow: inset 2px 5px 10px rgba(0,0,0,0.1),
                        inset -2px -5px 10px rgba(255,255,255,1),
                        15px 15px 10px rgba(0,0,0,0.05),
                        15px 10px 15px rgba(0,0,0,0.025);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 1.1rem;
                        color: var(--clr)
                    }

                    p{
                        display: flex;
                        justify-content: center;

                    
                    }
    
                }
            }


            .drop:nth-child(1):hover{
                border-radius: 50%;
            }

            .drop:nth-child(2):hover{
                border-radius: 50%;
            }

            .drop:nth-child(3):hover{
                border-radius: 25%;
            }
        }

        .bradburne-cover{
            
            display: flex;
            // justify-content: center;
            // align-items: center;

            .bradburne{
                margin: 5em auto;
                max-width: 34.15em;

                h5{
                    font-size: 1.5rem !important;
                    color: #4f2500;
                    text-align: center;
                }

                .john{

                    max-width: 34em;
                    padding-top: 2em;
                    padding-bottom: 2em;
                    position: relative;
                    
                    
                    h4{
                        text-align: center;
                    }

                    .my-div{
                        .img-inside{
                            img{
                                height: 180px;
                                width: 150px;
                            }

                            img:hover{
                                z-index: 3;
                                height: 300px;
                                width: 250px;
                            }
                            
                        }
                    } 
                }

                .john .my-div{
                    border: 3px solid #4f2500;
                    border-radius: 1em;
                    padding: 1.5em;
                    box-shadow: 0 0 0.5em #0d71fc;
                }

                .john:nth-child(odd){
                    border-left: 3px solid #4f2500;
                    padding-left: 3em;
                    transform: translateX(16.95em);
                    // float: left;
                    // clear: right;
                }

                .john:nth-child(even){
                    border-right: 3px solid #4f2500;
                    padding-right: 3em;
                    transform: translateX(-17em);
                    // float: right;
                    // clear: right;
                }

                .john:nth-child(odd)::before,
                .john:nth-child(even)::before{
                    content: '';
                    background: #4f2500;
                    width: 3em;
                    height: 3px;
                    position: absolute;
                    top: 50%;
                    //transform: translateX(-50%);
                }

                .john:nth-child(odd)::before{
                    left:0;
                }

                .john:nth-child(even)::before{
                    right:0;
                }

                .john:nth-child(odd) .my-div::before,
                .john:nth-child(even) .my-div::before{
                    content: '';
                    background: #fff;
                    box-shadow: 0 0 0.5em #0d71fc;
                    width:0.8em;
                    height: 0.8em;
                    position: absolute;
                    top:50%;
                    transform: translateY(-50%);
                    border-radius:50%;
                }

                .john:nth-child(odd) .my-div::before{
                    left: -0.5em;
                }

                .john:nth-child(even) .my-div::before{
                    right: -0.5em;
                }
            }
            // .drop{
            //     position: relative;
            //     width: 350px;
            //     height: 350px;
            //     box-shadow: inset 20px 20px 20px rgba(0,0,0,0.05),
            //     25px 35px 20px rgba(0,0,0,0.05),
            //     25px 30px 30px rgba(0,0,0,0.05),
            //     inset -20px -20px 25px rgba(255,255,255,0.9);
            //     transition: 0.5s ease-in-out;
            //     cursor: pointer;
            //     color:#4f2500;
    
            //     .content{
            //         position: relative;
            //         display: flex;
            //         justify-content: center;
            //         align-items: center;
            //         flex-direction: column;
            //         text-align: center;
            //         padding: 40px;
            //         gap: 15px;
    
            //         h4{
            //             position: relative;
            //             width: 200px;
            //             height: 120px;
            //             background: #4f2500;
            //             border-radius: 50%;
            //             box-shadow: inset 2px 5px 10px rgba(0,0,0,0.1),
            //             inset -2px -5px 10px rgba(255,255,255,1),
            //             15px 15px 10px rgba(0,0,0,0.05),
            //             15px 10px 15px rgba(0,0,0,0.025);
            //             display: flex;
            //             justify-content: center;
            //             align-items: center;
            //             font-size: 1.1rem;
            //             color: var(--clr)
            //         }

            //         p{
            //             display: flex;
            //             justify-content: center;

                    
            //         }
    
            //     }
            // } 
        }
    }

    
}

// @media only screen and (max-width: 768px) {
//     .text-description {
//         width: 80%; /* Adjust width for smaller screens */
//         top: 150px; /* Adjust positioning */
//         left: 10px; /* Adjust positioning */
//         p {
//             font-size: 12px; /* Adjust font size */
//         }
//         .btn-register {
//             padding: 3px; /* Adjust padding */
//             font-size: 11px; /* Adjust font size */
//         }
//     }
//     .banner4 {
//         height: 200px;
//         width: 100%;
//         img {
//             height: auto;
//             left: 50%;
//             transform: translateX(-50%);
//         }
//     }
// }

@media screen and (max-width:1150px){

    .bradburne{
        // margin: 5em auto;
        width: 80vw !important;

        h5{
            font-size: 1.5rem !important;
            color: #4f2500;
            text-align: center;
            margin-bottom: 40px !important;
        }

        .john{
            
            width: 100% !important;
            //margin-top: 10px !important;
            transform:none !important;
            padding-left: 0 !important;
            padding-right: 0 !important;
            border: none !important;
        }
        
        .my-div{
            border: 3px solid #4f2500 !important;
            border-radius: 1em !important;
            padding: 1.5em !important;
            box-shadow: 0 0 0.5em #0d71fc !important;
        }

        .my-div::before{
            top: -0.5em !important;
            left: 50% !important;
            transform: translateX(-50%) !important;
        }

        .john::before{
            width: 3px !important;
            height: 4em !important;
            top:-2em !important;
            left:50% !important;
            transform: translateX(-50%) !important;
        }
    }

  
}

@media screen and (max-width:1000px){
    .home-cover{
        //background: rgb(79,37,0);
        //background: linear-gradient(90deg, rgba(79,37,0,1) 0%, rgba(255,255,255,1) 10%, rgba(79,37,0,1) 20%, rgba(79,37,0,1) 35%, rgba(79,37,0,1) 50%, rgba(79,37,0,1) 65%, rgba(79,37,0,1) 80%, rgba(220,211,204,1) 90%, rgba(79,37,0,1) 100%);
        background: rgb(237,238,235);
        background: linear-gradient(90deg, rgba(237,238,235,1) 17%, rgba(237,238,235,1) 44%, rgba(237,238,235,1) 66%, rgba(237,238,235,1) 100%);
        background-repeat: no-repeat;
        color: #4f2500;
        //padding: 10px;

        .banner1, .banner2, .banner3, .banner4, .banner5{
            height: 100vh;
            width: 100%;
            position: absolute;
            left: 0;
            right: 0;
    
            img{
                position: absolute;
                width:100%;
                // left: 50%;
                // top: 25%;
                // transform: translate(-50%,-50%);
                // animation: zoom-out 3s linear infinite;
            }
    
            .text-description{
                width: 200px;
                position: absolute;
                top: 140px;
                left: 30px;
                animation: textup 12s linear infinite;
                transform: translateY(100px);
    
                h1{
                    margin-bottom: 5px;
                }
    
                span{
                    position: absolute;
                    height: 2px;
                    left: 0;
                    background-color: #4f2500;
                    width: 100px;
                }
    
                p{
                    font-size: 10px;
                    line-height: 18px;
                    margin-top: 20px;
                }
    
                .btn-register{
                    margin-top: 5px;
                    padding: 5px;
                    font-size: 10px;
                    background-color: #4f2500;
                    color: white;
                }
    
                .btn-register:hover{
                    background-color: #fff;
                    color: #4f2500;
                    transition: 2s;
                }
                 
            }
        }
    
        .landing{
            display: flex;
            flex-direction: column;
            position: relative;
            margin-top: 300px;
    
            .welcome{
                display: flex;
                justify-content: center;
                
                .option{
                    overflow: hidden;
                }
    
                .option li{
                    text-align: center;
                    font-size: 1.1rem !important;
                    letter-spacing: 1px;
                    position: relative;
                    top: 0;
                }     
    
                @keyframes animate{
                    40%, 60%{
                        left:100%;
                    }
    
                    100%{
                        left:100%;
                    }
                }
            }
    
            .d-design{
                height: 100vh;
                // background: url('../images/johnbradburn.jpg');
                // background-size: cover;
                // background-repeat: no-repeat;
                display: flex;
                justify-content: center;
                position: relative;
    
                .invitation-cover{
                        position: absolute;
                        margin: 10px auto;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        left: 0;
                        height: 200px;
                        width: 100%;
                        box-shadow:0px 3px 10px 5px #c7bcb3;
    
                        
                    .invitation{
                        padding: 10px;
                        //overflow: scroll;
                        display: flex;
                        flex-direction: column;
                        justify-content: start;
                        width: 90%;
    
                        .message{
                            border-bottom: 1px solid white;
                            p{
                                color: white !important;
                                
                                padding: 5px;
                            }
        
                        }
        
                        .message:hover{
                            cursor: pointer;
                            background: #4f2500;
                            color: white !important;
                            transition: 1s;
                        }
                    }
                    
    
                    h4{
                        text-transform: uppercase;
                    }

                    .btn-viewMessage{
                        margin-top: 10px;
                        height: 50px;
                        width: 100%;
                        padding: 10px 5px;
                        background-color: #4f2500;
                        cursor: pointer;
                        border-radius: 5px;
                        border: 1px solid transparent;
                    }
    
                    button a{
                        margin-top: 10px;
                        width: 160px;
                        padding: 10px 5px;
                        //background-color: #4f2500;
                        color: #fff !important;
                        cursor: pointer;
                        border-radius: 5px;     
                    }
    
                    .btn-viewMessage:hover{
                        border: 1px solid #4f2500;
                        background-color: white;
                        transition: 1s;
    
                        a{
                            color: #4f2500 !important;
                        }
                    }
                }
    
                
                .brad-background{
                    //width: 60%;
                    height: 100vh;
                    // background: url('../images/johnbradburn.jpg');
                    // background-size: cover;
                    // background-repeat: no-repeat;
                    background-position-y: 20%;
    
                    display: flex;
                    justify-content: center;
                    align-items: end;
                    flex-wrap: wrap;
                    padding: 10px 0;
                    gap:40px 60px;
    
                    .drop{
                        position: relative;
                        width: 500px;
                        height: 500px;
                        box-shadow: inset 20px 20px 20px rgba(0,0,0,0.05),
                        25px 35px 20px rgba(0,0,0,0.05),
                        25px 30px 30px rgba(0,0,0,0.05),
                        inset -20px -20px 25px rgba(255,255,255,0.9);
                        transition: 0.5s ease-in-out;
                        cursor: pointer;
                        color:#4f2500;
            
                        .content{
                            position: relative;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-direction: column;
                            text-align: center;
                            padding: 40px;
                            gap: 15px;
            
                            h4{
                                position: relative;
                                width: 200px;
                                height: 120px;
                                background: #eff0f4;
                                border-radius: 50%;
                                box-shadow: inset 2px 5px 10px rgba(0,0,0,0.1),
                                inset -2px -5px 10px rgba(255,255,255,1),
                                15px 15px 10px rgba(0,0,0,0.05),
                                15px 10px 15px rgba(0,0,0,0.025);
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                font-size: 1.1rem;
                                color: var(--clr)
                            }
        
                            p{
                                display: flex;
                                justify-content: center;
        
                                
                
                                span{
                                    background: #4f2500;
                                border-radius: 25px;
                                padding: 5px;
                                font-size: 1.2rem;
                                color: white;
                                }
                
                                span:before{
                                    content: '';
                                    animation: anim infinite 30s;
                                }
                
                                @keyframes anim{
                                    0%{
                                        content: 'Mutemwa Leprosy Settlement was established in 1937 as a Leprosium.';
                                    }
                                    25%{
                                        content: 'In 1969, John Randal Bradburne (14 June 1921 - 05 September 1979) became the warden of the settlement.';
                                    }
                                    50%{
                                        content: 'He cares for the sick in the house.';
                                    }
                                    75%{
                                        content: 'Currently, Mutemwa Leprosy and Care Centre hold an average of 32 residents.';
                                    }
                                    100%{
                                        content: 'Mutemwa Settlement is in Mutoko a small town in Mashonaland East Province of Zimbabwe. It lies 143km from Harare.';
                                    }
                                }
                            }
            
                        }
                    }
            
                    .drop:nth-child(1){
                        border-radius: 75% 25% 44% 56% / 56% 32% 68% 44%;
                    }
                }
                
            }
    
            .vision{
                height: 100%;
                // background: url('../images/mountainCross-01.png');
                // background-size: cover;
                // background-repeat: no-repeat;
                background-position-y: 17%;
    
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                padding: 10px 0;
                gap:40px 60px;
    
                .drop{
                    position: relative;
                    width: 350px;
                    height: 450px;
                    background: #eff0f4;
                    box-shadow: inset 20px 20px 20px rgba(0,0,0,0.05),
                    25px 35px 20px rgba(0,0,0,0.05),
                    25px 30px 30px rgba(0,0,0,0.05),
                    inset -20px -20px 25px rgb(255,255,255);
                    transition: 0.5s ease-in-out;
                    cursor: pointer;
                    color:#4f2500;
        
                    .content{
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        text-align: center;
                        padding: 40px;
                        gap: 15px;
    
                        .svg{
                            img{
                                width: 50px;
                                height: 50px;
                            }
                        }
        
                        h4{
                            position: relative;
                            width: 200px;
                            height: 120px;
                            background: #eff0f4;
                            border-radius: 50%;
                            box-shadow: inset 2px 5px 10px rgba(0,0,0,0.1),
                            inset -2px -5px 10px rgba(255,255,255,0.1),
                            15px 15px 10px rgba(0,0,0,0.05),
                            15px 10px 15px rgba(0,0,0,0.025);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-size: 1.1rem;
                            color: var(--clr)
                        }
    
                        p{
                            display: flex;
                            justify-content: center;
    
                        
                        }
        
                    }
                }
    
                .drop:nth-child(1){
                    position: relative;
                    width: 350px;
                    height: 450px;
                    background: #eff0f4;
                    box-shadow: inset 20px 20px 20px rgba(0,0,0,0.1),
                    25px 35px 20px rgba(0,0,0,0.05),
                    25px 30px 30px rgba(0,0,0,0.05),
                    inset -20px -20px 25px rgba(255,255,255,1);
                    transition: 0.5s ease-in-out;
                    cursor: pointer;
                    color:#4f2500;
        
                    .content{
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        text-align: center;
                        padding: 40px;
                        gap: 15px;
        
                        h4{
                            position: relative;
                            width: 200px;
                            height: 120px;
                            background: #eff0f4;
                            border-radius: 50%;
                            box-shadow: inset 2px 5px 10px rgba(0,0,0,0.1),
                            inset -2px -5px 10px rgba(255,255,255,1),
                            15px 15px 10px rgba(0,0,0,0.05),
                            15px 10px 15px rgba(0,0,0,0.025);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-size: 1.1rem;
                            color: var(--clr)
                        }
    
                        p{
                            display: flex;
                            justify-content: center;
    
                        
                        }
        
                    }
                }

                .drop:nth-child(2){
                    position: relative;
                    width: 350px;
                    height: 450px;
                    background: #eff0f4;
                    box-shadow: inset 20px 20px 20px rgba(0,0,0,0.1),
                    25px 35px 20px rgba(0,0,0,0.05),
                    25px 30px 30px rgba(0,0,0,0.05),
                    inset -20px -20px 25px rgba(255,255,255,1);
                    transition: 0.5s ease-in-out;
                    cursor: pointer;
                    color:#4f2500;
        
                    .content{
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        text-align: center;
                        padding: 40px;
                        gap: 15px;
        
                        h4{
                            position: relative;
                            width: 200px;
                            height: 120px;
                            background: #eff0f4;
                            border-radius: 50%;
                            box-shadow: inset 2px 5px 10px rgba(0,0,0,0.1),
                            inset -2px -5px 10px rgba(255,255,255,1),
                            15px 15px 10px rgba(0,0,0,0.05),
                            15px 10px 15px rgba(0,0,0,0.025);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-size: 1.1rem;
                            color: var(--clr)
                        }
    
                        p{
                            display: flex;
                            justify-content: center;
    
                        
                        }
        
                    }
                }
    
                .drop:nth-child(3){
                    position: relative;
                    width: 350px;
                    height: 650px;
                    background: #eff0f4;
                    box-shadow: inset 20px 20px 20px rgba(0,0,0,0.1),
                    25px 35px 20px rgba(0,0,0,0.05),
                    25px 30px 30px rgba(0,0,0,0.05),
                    inset -20px -20px 25px rgba(255,255,255,1);
                    transition: 0.5s ease-in-out;
                    cursor: pointer;
                    color:#4f2500;
        
                    .content{
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        text-align: center;
                        padding: 40px;
                        gap: 15px;
        
                        h4{
                            position: relative;
                            width: 200px;
                            height: 120px;
                            background: #eff0f4;
                            border-radius: 50%;
                            box-shadow: inset 2px 5px 10px rgba(0,0,0,0.1),
                            inset -2px -5px 10px rgba(255,255,255,1),
                            15px 15px 10px rgba(0,0,0,0.05),
                            15px 10px 15px rgba(0,0,0,0.025);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-size: 1.1rem;
                            color: var(--clr)
                        }
    
                        p{
                            display: flex;
                            justify-content: center;
    
                        
                        }
        
                    }
                }
    
                .drop:nth-child(1):hover{
                    border-radius: 50%;
                }
    
                .drop:nth-child(2):hover{
                    border-radius: 50%;
                }
    
                .drop:nth-child(3):hover{
                    border-radius: 25%;
                }
            }
    
            .bradburne-cover{
                
                display: flex;
                // justify-content: center;
                // align-items: center;
    
                .bradburne{
                    // margin: 5em auto;
                    width: 80vw !important;
            
                    h5{
                        font-size: 1.5rem !important;
                        color: #4f2500;
                        text-align: center;
                        margin-bottom: 40px !important;
                    }
            
                    .john{
                        
                        width: 100% !important;
                        //margin-top: 10px !important;
                        transform:none !important;
                        padding-left: 0 !important;
                        padding-right: 0 !important;
                        border: none !important;
                    }
                    
                    .my-div{
                        border: 3px solid #4f2500 !important;
                        border-radius: 1em !important;
                        padding: 1.5em !important;
                        box-shadow: 0 0 0.5em #0d71fc !important;
                    }
            
                    .my-div::before{
                        top: -0.5em !important;
                        left: 50% !important;
                        transform: translateX(-50%) !important;
                    }
            
                    .john::before{
                        width: 3px !important;
                        height: 4em !important;
                        top:-2em !important;
                        left:50% !important;
                        transform: translateX(-50%) !important;
                    }
                }
               
            }
        }
    
        
    }
}

@media screen and (max-width:986px){
    .home-cover{
        //background: rgb(79,37,0);
        //background: linear-gradient(90deg, rgba(79,37,0,1) 0%, rgba(255,255,255,1) 10%, rgba(79,37,0,1) 20%, rgba(79,37,0,1) 35%, rgba(79,37,0,1) 50%, rgba(79,37,0,1) 65%, rgba(79,37,0,1) 80%, rgba(220,211,204,1) 90%, rgba(79,37,0,1) 100%);
        background: rgb(237,238,235);
        background: linear-gradient(90deg, rgba(237,238,235,1) 17%, rgba(237,238,235,1) 44%, rgba(237,238,235,1) 66%, rgba(237,238,235,1) 100%);
        background-repeat: no-repeat;
        color: #4f2500;
        //padding: 10px;
    
        .landing{
            display: flex;
            flex-direction: column;
            position: relative;
    
            .welcome{
                display: flex;
                justify-content: center;
                
                .option{
                    overflow: hidden;
                }
    
                .option li{
                    text-align: center;
                    font-size: 1.1rem !important;
                    letter-spacing: 1px;
                    position: relative;
                    top: 0;
                }     
    
                @keyframes animate{
                    40%, 60%{
                        left:100%;
                    }
    
                    100%{
                        left:100%;
                    }
                }
            }
    
            .d-design{
                height: 100vh;
                // background: url('../images/johnbradburn.jpg');
                // background-size: cover;
                // background-repeat: no-repeat;
                display: flex;
                justify-content: center;
                position: relative;
    
                .invitation-cover{
                        position: absolute;
                        margin: 10px auto;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        left: 0;
                        height: 200px;
                        width: 100%;
                        box-shadow:0px 3px 10px 5px #c7bcb3;
    
                        
                    .invitation{
                        padding: 10px;
                        //overflow: scroll;
                        display: flex;
                        flex-direction: column;
                        justify-content: start;
                        width: 90%;
    
                        .message{
                            border-bottom: 1px solid white;
                            p{
                                color: white !important;
                                
                                padding: 5px;
                            }
        
                        }
        
                        .message:hover{
                            cursor: pointer;
                            background: #4f2500;
                            color: white !important;
                            transition: 1s;
                        }
                    }
                    
    
                    h4{
                        text-transform: uppercase;
                    }

                    .btn-viewMessage{
                        margin-top: 10px;
                        height: 50px;
                        width: 100%;
                        padding: 10px 5px;
                        background-color: #4f2500;
                        cursor: pointer;
                        border-radius: 5px;
                        border: 1px solid transparent;
                    }
    
                    button a{
                        margin-top: 10px;
                        width: 160px;
                        padding: 10px 5px;
                        //background-color: #4f2500;
                        color: #fff !important;
                        cursor: pointer;
                        border-radius: 5px;     
                    }
    
                    .btn-viewMessage:hover{
                        border: 1px solid #4f2500;
                        background-color: white;
                        transition: 1s;
    
                        a{
                            color: #4f2500 !important;
                        }
                    }
                }
    
                
                .brad-background{
                    //width: 60%;
                    height: 80vh;
                    // background: url('../images/johnbradburn.jpg');
                    // background-size: cover;
                    // background-repeat: no-repeat;
                    background-position-y: 20%;
    
                    display: flex;
                    justify-content: center;
                    align-items: end;
                    flex-wrap: wrap;
                    padding: 10px 0;
                    gap:40px 60px;
    
                    .drop{
                        position: relative;
                        width: 350px;
                        height: 350px;
                        box-shadow: inset 20px 20px 20px rgba(0,0,0,0.05),
                        25px 35px 20px rgba(0,0,0,0.05),
                        25px 30px 30px rgba(0,0,0,0.05),
                        inset -20px -20px 25px rgba(255,255,255,0.9);
                        transition: 0.5s ease-in-out;
                        cursor: pointer;
                        color:#4f2500;
            
                        .content{
                            position: relative;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-direction: column;
                            text-align: center;
                            padding: 40px;
                            gap: 15px;
            
                            h4{
                                position: relative;
                                width: 170px;
                                height: 120px;
                                background: #eff0f4;
                                border-radius: 50%;
                                box-shadow: inset 2px 5px 10px rgba(0,0,0,0.1),
                                inset -2px -5px 10px rgba(255,255,255,1),
                                15px 15px 10px rgba(0,0,0,0.05),
                                15px 10px 15px rgba(0,0,0,0.025);
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                font-size: .8rem;
                                color: var(--clr)
                            }
        
                            p{
                                display: flex;
                                justify-content: center;
        
                                
                
                                span{
                                    background: #4f2500;
                                border-radius: 25px;
                                padding: 5px;
                                font-size: .8rem;
                                color: white;
                                }
                
                                span:before{
                                    content: '';
                                    animation: anim infinite 30s;
                                }
                
                                @keyframes anim{
                                    0%{
                                        content: 'Mutemwa Leprosy Settlement was established in 1937 as a Leprosium.';
                                    }
                                    25%{
                                        content: 'In 1969, John Randal Bradburne (14 June 1921 - 05 September 1979) became the warden of the settlement.';
                                    }
                                    50%{
                                        content: 'He cares for the sick in the house.';
                                    }
                                    75%{
                                        content: 'Currently, Mutemwa Leprosy and Care Centre hold an average of 32 residents.';
                                    }
                                    100%{
                                        content: 'Mutemwa Settlement is in Mutoko a small town in Mashonaland East Province of Zimbabwe. It lies 143km from Harare.';
                                    }
                                }
                            }
            
                        }
                    }
            
                    .drop:nth-child(1){
                        border-radius: 75% 25% 44% 56% / 56% 32% 68% 44%;
                    }
                }
                
            }
    
            .vision{
                height: 100%;
                // background: url('../images/mountainCross-01.png');
                // background-size: cover;
                // background-repeat: no-repeat;
                background-position-y: 17%;
    
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                padding: 10px 0;
                gap:40px 60px;
    
                .drop{
                    position: relative;
                    width: 250px;
                    height: 350px;
                    background: #eff0f4;
                    box-shadow: inset 20px 20px 20px rgba(0,0,0,0.05),
                    25px 35px 20px rgba(0,0,0,0.05),
                    25px 30px 30px rgba(0,0,0,0.05),
                    inset -20px -20px 25px rgb(255,255,255);
                    transition: 0.5s ease-in-out;
                    cursor: pointer;
                    color:#4f2500;
        
                    .content{
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        text-align: center;
                        padding: 40px;
                        gap: 15px;
    
                        .svg{
                            img{
                                width: 50px;
                                height: 50px;
                            }
                        }
        
                        h4{
                            position: relative;
                            width: 200px;
                            height: 120px;
                            background: #eff0f4;
                            border-radius: 50%;
                            box-shadow: inset 2px 5px 10px rgba(0,0,0,0.1),
                            inset -2px -5px 10px rgba(255,255,255,0.1),
                            15px 15px 10px rgba(0,0,0,0.05),
                            15px 10px 15px rgba(0,0,0,0.025);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-size: 1.1rem;
                            color: var(--clr)
                        }
    
                        p{
                            display: flex;
                            justify-content: center;
    
                        
                        }
        
                    }
                }
    
                .drop:nth-child(3){
                    position: relative;
                    width: 350px;
                    height: 650px;
                    background: #eff0f4;
                    box-shadow: inset 20px 20px 20px rgba(0,0,0,0.1),
                    25px 35px 20px rgba(0,0,0,0.05),
                    25px 30px 30px rgba(0,0,0,0.05),
                    inset -20px -20px 25px rgba(255,255,255,1);
                    transition: 0.5s ease-in-out;
                    cursor: pointer;
                    color:#4f2500;
        
                    .content{
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        text-align: center;
                        padding: 40px;
                        gap: 15px;
        
                        h4{
                            position: relative;
                            width: 200px;
                            height: 120px;
                            background: #eff0f4;
                            border-radius: 50%;
                            box-shadow: inset 2px 5px 10px rgba(0,0,0,0.1),
                            inset -2px -5px 10px rgba(255,255,255,1),
                            15px 15px 10px rgba(0,0,0,0.05),
                            15px 10px 15px rgba(0,0,0,0.025);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-size: 1.1rem;
                            color: var(--clr)
                        }
    
                        p{
                            display: flex;
                            justify-content: center;
    
                        
                        }
        
                    }
                }
    
    
                .drop:nth-child(1):hover{
                    border-radius: 50%;
                }
    
                .drop:nth-child(2):hover{
                    border-radius: 50%;
                }
    
                .drop:nth-child(3):hover{
                    border-radius: 25%;
                }
            }
    
            .bradburne-cover{
                
                display: flex;
                // justify-content: center;
                // align-items: center;
    
                .bradburne{
                    // margin: 5em auto;
                    width: 80vw !important;
            
                    h5{
                        font-size: 1.5rem !important;
                        color: #4f2500;
                        text-align: center;
                        margin-bottom: 40px !important;
                    }
            
                    .john{
                        
                        width: 100% !important;
                        //margin-top: 10px !important;
                        transform:none !important;
                        padding-left: 0 !important;
                        padding-right: 0 !important;
                        border: none !important;
                    }
                    
                    .my-div{
                        border: 3px solid #4f2500 !important;
                        border-radius: 1em !important;
                        padding: 1.5em !important;
                        box-shadow: 0 0 0.5em #0d71fc !important;
                    }
            
                    .my-div::before{
                        top: -0.5em !important;
                        left: 50% !important;
                        transform: translateX(-50%) !important;
                    }
            
                    .john::before{
                        width: 3px !important;
                        height: 4em !important;
                        top:-2em !important;
                        left:50% !important;
                        transform: translateX(-50%) !important;
                    }
                }
               
            }
        }
    
        
    }
}

@media screen and (max-width:900px){
    .home-cover{
        //background: rgb(79,37,0);
        //background: linear-gradient(90deg, rgba(79,37,0,1) 0%, rgba(255,255,255,1) 10%, rgba(79,37,0,1) 20%, rgba(79,37,0,1) 35%, rgba(79,37,0,1) 50%, rgba(79,37,0,1) 65%, rgba(79,37,0,1) 80%, rgba(220,211,204,1) 90%, rgba(79,37,0,1) 100%);
        background: rgb(237,238,235);
        background: linear-gradient(90deg, rgba(237,238,235,1) 17%, rgba(237,238,235,1) 44%, rgba(237,238,235,1) 66%, rgba(237,238,235,1) 100%);
        background-repeat: no-repeat;
        color: #4f2500;
        //padding: 10px;
    
        .banner1, .banner2, .banner3, .banner5{
            height: 100vh;
            width: 100%;
            position: absolute;
            left: 0;
            right: 0;
            z-index: 1;
    
            img{
                position: absolute;
                width: 100%;
    
            }
    
            .text-description{
                width: 100px;
                position: absolute;
                top: 10px;
                left: 10;
                animation: textup 12s linear infinite;
                transform: translateY(100px);
    
                h1{
                    margin-bottom: 5px;
                }
    
                span{
                    position: absolute;
                    height: 2px;
                    left: 0;
                    background-color: #4f2500;
                    width: 100px;
                }
    
                p{
                    font-size: 14px;
                    line-height: 18px;
                    margin-top: 20px;
                }
    
                .btn-register{
                    margin-top: 5px;
                    padding: 5px;
                    font-size: 13px;
                    background-color: #4f2500;
                    color: white;
    
                    a{
                        list-style: none;
                        color: #c7bcb3;
                    }
                }
    
                .btn-register:hover{
                    background-color: #fff;
                    color: #000;
                    transition: 2s;
    
                    a{
                        list-style: none;
                        color: #000;
                    }
                }
                 
            }
        }
    
        .banner4{
            height: 300px;
            width: 500px;
            position: absolute;
            left: 0;
            right: 0;
            z-index: 2;
            margin-bottom: 20px;
    
            img{
                position: absolute;
                height: 300px;
                width: 100%;
                left: 50%;
            }
    
            .text-description{
                width: 100px;
                position: absolute;
                top: 20px;
                left: 10;
                animation: textup 12s linear infinite;
                transform: translateY(100px);
    
                h1{
                    margin-bottom: 5px;
                }
    
                span{
                    position: absolute;
                    height: 2px;
                    left: 0;
                    background-color: #4f2500;
                    width: 100px;
                }
    
                p{
                    font-size: 14px;
                    line-height: 18px;
                    margin-top: 20px;
                }
    
                .btn-register{
                    margin-top: 5px;
                    padding: 5px;
                    font-size: 13px;
                    background-color: #4f2500;
                    color: white;
    
                    a{
                        list-style: none;
                        color: #c7bcb3;
                    }
                }
    
                .btn-register:hover{
                    background-color: #fff;
                    color: #000;
                    transition: 2s;
    
                    a{
                        list-style: none;
                        color: #000;
                    }
                }
                 
            }
        }
        .landing{
            display: flex;
            flex-direction: column;
            position: relative;
    
            .welcome{
                display: flex;
                justify-content: center;
                
                .option{
                    overflow: hidden;
                }
    
                .option li{
                    text-align: center;
                    font-size: 1.1rem !important;
                    letter-spacing: 1px;
                    position: relative;
                    top: 0;
                }     
    
                @keyframes animate{
                    40%, 60%{
                        left:100%;
                    }
    
                    100%{
                        left:100%;
                    }
                }
            }
    
            .d-design{
                height: 100vh;
                // background: url('../images/johnbradburn.jpg');
                // background-size: cover;
                // background-repeat: no-repeat;
                display: flex;
                justify-content: center;
                position: relative;
    
                .invitation-cover{
                        position: absolute;
                        margin: 10px auto;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        left: 0;
                        height: 200px;
                        width: 100%;
                        box-shadow:0px 3px 10px 5px #c7bcb3;
    
                        
                    .invitation{
                        padding: 10px;
                        //overflow: scroll;
                        display: flex;
                        flex-direction: column;
                        justify-content: start;
                        width: 90%;
    
                        .message{
                            border-bottom: 1px solid white;
                            p{
                                color: white !important;
                                
                                padding: 5px;
                            }
        
                        }
        
                        .message:hover{
                            cursor: pointer;
                            background: #4f2500;
                            color: white !important;
                            transition: 1s;
                        }
                    }
                    
    
                    h4{
                        text-transform: uppercase;
                    }

                    .btn-viewMessage{
                        margin-top: 10px;
                        height: 50px;
                        width: 100%;
                        padding: 10px 5px;
                        background-color: #4f2500;
                        cursor: pointer;
                        border-radius: 5px;
                        border: 1px solid transparent;
                    }
    
                    button a{
                        margin-top: 10px;
                        width: 160px;
                        padding: 10px 5px;
                        //background-color: #4f2500;
                        color: #fff !important;
                        cursor: pointer;
                        border-radius: 5px;     
                    }
    
                    .btn-viewMessage:hover{
                        border: 1px solid #4f2500;
                        background-color: white;
                        transition: 1s;
    
                        a{
                            color: #4f2500 !important;
                        }
                    }
                }
    
                
                .brad-background{
                    //width: 60%;
                    height: 80vh;
                    // background: url('../images/johnbradburn.jpg');
                    // background-size: cover;
                    // background-repeat: no-repeat;
                    background-position-y: 20%;
    
                    display: flex;
                    justify-content: center;
                    align-items: end;
                    flex-wrap: wrap;
                    padding: 10px 0;
                    gap:40px 60px;
    
                    .drop{
                        position: relative;
                        width: 350px;
                        height: 350px;
                        box-shadow: inset 20px 20px 20px rgba(0,0,0,0.05),
                        25px 35px 20px rgba(0,0,0,0.05),
                        25px 30px 30px rgba(0,0,0,0.05),
                        inset -20px -20px 25px rgba(255,255,255,0.9);
                        transition: 0.5s ease-in-out;
                        cursor: pointer;
                        color:#4f2500;
            
                        .content{
                            position: relative;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-direction: column;
                            text-align: center;
                            padding: 40px;
                            gap: 15px;
            
                            h4{
                                position: relative;
                                width: 170px;
                                height: 120px;
                                background: #eff0f4;
                                border-radius: 50%;
                                box-shadow: inset 2px 5px 10px rgba(0,0,0,0.1),
                                inset -2px -5px 10px rgba(255,255,255,1),
                                15px 15px 10px rgba(0,0,0,0.05),
                                15px 10px 15px rgba(0,0,0,0.025);
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                font-size: .8rem;
                                color: var(--clr)
                            }
        
                            p{
                                display: flex;
                                justify-content: center;
        
                                
                
                                span{
                                    background: #4f2500;
                                border-radius: 25px;
                                padding: 5px;
                                font-size: .8rem;
                                color: white;
                                }
                
                                span:before{
                                    content: '';
                                    animation: anim infinite 30s;
                                }
                
                                @keyframes anim{
                                    0%{
                                        content: 'Mutemwa Leprosy Settlement was established in 1937 as a Leprosium.';
                                    }
                                    25%{
                                        content: 'In 1969, John Randal Bradburne (14 June 1921 - 05 September 1979) became the warden of the settlement.';
                                    }
                                    50%{
                                        content: 'He cares for the sick in the house.';
                                    }
                                    75%{
                                        content: 'Currently, Mutemwa Leprosy and Care Centre hold an average of 32 residents.';
                                    }
                                    100%{
                                        content: 'Mutemwa Settlement is in Mutoko a small town in Mashonaland East Province of Zimbabwe. It lies 143km from Harare.';
                                    }
                                }
                            }
            
                        }
                    }
            
                    .drop:nth-child(1){
                        border-radius: 75% 25% 44% 56% / 56% 32% 68% 44%;
                    }
                }
                
            }
    
            .vision{
                height: 100%;
                // background: url('../images/mountainCross-01.png');
                // background-size: cover;
                // background-repeat: no-repeat;
                background-position-y: 17%;
    
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                padding: 10px 0;
                gap:40px 60px;
    
                .drop{
                    position: relative;
                    width: 250px;
                    height: 350px;
                    background: #eff0f4;
                    box-shadow: inset 20px 20px 20px rgba(0,0,0,0.05),
                    25px 35px 20px rgba(0,0,0,0.05),
                    25px 30px 30px rgba(0,0,0,0.05),
                    inset -20px -20px 25px rgb(255,255,255);
                    transition: 0.5s ease-in-out;
                    cursor: pointer;
                    color:#4f2500;
        
                    .content{
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        text-align: center;
                        padding: 40px;
                        gap: 15px;
    
                        .svg{
                            img{
                                width: 50px;
                                height: 50px;
                            }
                        }
        
                        h4{
                            position: relative;
                            width: 200px;
                            height: 120px;
                            background: #eff0f4;
                            border-radius: 50%;
                            box-shadow: inset 2px 5px 10px rgba(0,0,0,0.1),
                            inset -2px -5px 10px rgba(255,255,255,0.1),
                            15px 15px 10px rgba(0,0,0,0.05),
                            15px 10px 15px rgba(0,0,0,0.025);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-size: 1.1rem;
                            color: var(--clr)
                        }
    
                        p{
                            display: flex;
                            justify-content: center;
    
                        
                        }
        
                    }
                }
    
                .drop:nth-child(3){
                    position: relative;
                    width: 350px;
                    height: 650px;
                    background: #eff0f4;
                    box-shadow: inset 20px 20px 20px rgba(0,0,0,0.1),
                    25px 35px 20px rgba(0,0,0,0.05),
                    25px 30px 30px rgba(0,0,0,0.05),
                    inset -20px -20px 25px rgba(255,255,255,1);
                    transition: 0.5s ease-in-out;
                    cursor: pointer;
                    color:#4f2500;
        
                    .content{
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        text-align: center;
                        padding: 40px;
                        gap: 15px;
        
                        h4{
                            position: relative;
                            width: 200px;
                            height: 120px;
                            background: #eff0f4;
                            border-radius: 50%;
                            box-shadow: inset 2px 5px 10px rgba(0,0,0,0.1),
                            inset -2px -5px 10px rgba(255,255,255,1),
                            15px 15px 10px rgba(0,0,0,0.05),
                            15px 10px 15px rgba(0,0,0,0.025);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-size: 1.1rem;
                            color: var(--clr)
                        }
    
                        p{
                            display: flex;
                            justify-content: center;
    
                        
                        }
        
                    }
                }
    
    
                .drop:nth-child(1):hover{
                    border-radius: 50%;
                }
    
                .drop:nth-child(2):hover{
                    border-radius: 50%;
                }
    
                .drop:nth-child(3):hover{
                    border-radius: 25%;
                }
            }
    
            .bradburne-cover{
                
                display: flex;
                // justify-content: center;
                // align-items: center;
    
                .bradburne{
                    // margin: 5em auto;
                    width: 80vw !important;
            
                    h5{
                        font-size: 1.5rem !important;
                        color: #4f2500;
                        text-align: center;
                        margin-bottom: 40px !important;
                    }
            
                    .john{
                        
                        width: 100% !important;
                        //margin-top: 10px !important;
                        transform:none !important;
                        padding-left: 0 !important;
                        padding-right: 0 !important;
                        border: none !important;
                    }
                    
                    .my-div{
                        border: 3px solid #4f2500 !important;
                        border-radius: 1em !important;
                        padding: 1.5em !important;
                        box-shadow: 0 0 0.5em #0d71fc !important;
                    }
            
                    .my-div::before{
                        top: -0.5em !important;
                        left: 50% !important;
                        transform: translateX(-50%) !important;
                    }
            
                    .john::before{
                        width: 3px !important;
                        height: 4em !important;
                        top:-2em !important;
                        left:50% !important;
                        transform: translateX(-50%) !important;
                    }
                }
               
            }
        }
    
        
    }
}

@media screen and (max-width:600px){
    .home-cover{
        //background: rgb(79,37,0);
        //background: linear-gradient(90deg, rgba(79,37,0,1) 0%, rgba(255,255,255,1) 10%, rgba(79,37,0,1) 20%, rgba(79,37,0,1) 35%, rgba(79,37,0,1) 50%, rgba(79,37,0,1) 65%, rgba(79,37,0,1) 80%, rgba(220,211,204,1) 90%, rgba(79,37,0,1) 100%);
        background: rgb(237,238,235);
        background: linear-gradient(90deg, rgba(237,238,235,1) 17%, rgba(237,238,235,1) 44%, rgba(237,238,235,1) 66%, rgba(237,238,235,1) 100%);
        background-repeat: no-repeat;
        color: #4f2500;
        //padding: 10px;

        .banner1, .banner2, .banner3, .banner5{
            height: 100vh;
            width: 100%;
            position: absolute;
            left: 0;
            right: 0;
            z-index: 1;
    
            img{
                position: absolute;
                width: 100%;
    
            }
    
            .text-description{
                width: 100px;
                position: absolute;
                top: 10px;
                left: 10;
                animation: textup 12s linear infinite;
                transform: translateY(100px);
    
                h1{
                    margin-bottom: 5px;
                }
    
                span{
                    position: absolute;
                    height: 2px;
                    left: 0;
                    background-color: #4f2500;
                    width: 100px;
                }
    
                p{
                    font-size: 14px;
                    line-height: 18px;
                    margin-top: 20px;
                }
    
                .btn-register{
                    margin-top: 5px;
                    padding: 5px;
                    font-size: 13px;
                    background-color: #4f2500;
                    color: white;
    
                    a{
                        list-style: none;
                        color: #c7bcb3;
                    }
                }
    
                .btn-register:hover{
                    background-color: #fff;
                    color: #000;
                    transition: 2s;
    
                    a{
                        list-style: none;
                        color: #000;
                    }
                }
                 
            }
        }
    
        .banner4{
            height: 300px;
            width: 500px;
            position: absolute;
            left: 0;
            right: 0;
            z-index: 2;
            margin-bottom: 20px;
    
            img{
                position: absolute;
                height: 300px;
                width: 100%;
                left: 50%;
            }
    
            .text-description{
                width: 100px;
                position: absolute;
                top: 20px;
                left: 10;
                animation: textup 12s linear infinite;
                transform: translateY(100px);
    
                h1{
                    margin-bottom: 5px;
                }
    
                span{
                    position: absolute;
                    height: 2px;
                    left: 0;
                    background-color: #4f2500;
                    width: 100px;
                }
    
                p{
                    font-size: 14px;
                    line-height: 18px;
                    margin-top: 20px;
                }
    
                .btn-register{
                    margin-top: 5px;
                    padding: 5px;
                    font-size: 13px;
                    background-color: #4f2500;
                    color: white;
    
                    a{
                        list-style: none;
                        color: #c7bcb3;
                    }
                }
    
                .btn-register:hover{
                    background-color: #fff;
                    color: #000;
                    transition: 2s;
    
                    a{
                        list-style: none;
                        color: #000;
                    }
                }
                 
            }
        }
        .landing{
            display: flex;
            flex-direction: column;
            position: relative;
    
            .welcome{
                display: flex;
                justify-content: center;
                
                .option{
                    overflow: hidden;
                }
    
                .option li{
                    text-align: center;
                    font-size: 1.1rem !important;
                    letter-spacing: 1px;
                    position: relative;
                    top: 0;
                }     
    
                @keyframes animate{
                    40%, 60%{
                        left:100%;
                    }
    
                    100%{
                        left:100%;
                    }
                }
            }
    
            .d-design{
                height: 100vh;
                // background: url('../images/johnbradburn.jpg');
                // background-size: cover;
                // background-repeat: no-repeat;
                display: flex;
                justify-content: center;
                position: relative;
    
                .invitation-cover{
                        position: absolute;
                        margin: 10px auto;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        left: 0;
                        height: 200px;
                        width: 100%;
                        box-shadow:0px 3px 10px 5px #c7bcb3;
    
                        
                    .invitation{
                        padding: 10px;
                        //overflow: scroll;
                        display: flex;
                        flex-direction: column;
                        justify-content: start;
                        width: 90%;
    
                        .message{
                            border-bottom: 1px solid white;
                            p{
                                color: white !important;
                                
                                padding: 5px;
                            }
        
                        }
        
                        .message:hover{
                            cursor: pointer;
                            background: #4f2500;
                            color: white !important;
                            transition: 1s;
                        }
                    }
                    
    
                    h4{
                        text-transform: uppercase;
                    }

                    .btn-viewMessage{
                        margin-top: 10px;
                        height: 50px;
                        width: 100%;
                        padding: 10px 5px;
                        background-color: #4f2500;
                        cursor: pointer;
                        border-radius: 5px;
                        border: 1px solid transparent;
                    }
    
                    button a{
                        margin-top: 10px;
                        width: 160px;
                        padding: 10px 5px;
                        //background-color: #4f2500;
                        color: #fff !important;
                        cursor: pointer;
                        border-radius: 5px;     
                    }
    
                    .btn-viewMessage:hover{
                        border: 1px solid #4f2500;
                        background-color: white;
                        transition: 1s;
    
                        a{
                            color: #4f2500 !important;
                        }
                    }
                }
    
                
                .brad-background{
                    //width: 60%;
                    height: 80vh;
                    // background: url('../images/johnbradburn.jpg');
                    // background-size: cover;
                    // background-repeat: no-repeat;
                    background-position-y: 20%;
    
                    display: flex;
                    justify-content: center;
                    align-items: end;
                    flex-wrap: wrap;
                    padding: 10px 0;
                    gap:40px 60px;
    
                    .drop{
                        position: relative;
                        width: 350px;
                        height: 350px;
                        box-shadow: inset 20px 20px 20px rgba(0,0,0,0.05),
                        25px 35px 20px rgba(0,0,0,0.05),
                        25px 30px 30px rgba(0,0,0,0.05),
                        inset -20px -20px 25px rgba(255,255,255,0.9);
                        transition: 0.5s ease-in-out;
                        cursor: pointer;
                        color:#4f2500;
            
                        .content{
                            position: relative;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-direction: column;
                            text-align: center;
                            padding: 40px;
                            gap: 15px;
            
                            h4{
                                position: relative;
                                width: 170px;
                                height: 120px;
                                background: #eff0f4;
                                border-radius: 50%;
                                box-shadow: inset 2px 5px 10px rgba(0,0,0,0.1),
                                inset -2px -5px 10px rgba(255,255,255,1),
                                15px 15px 10px rgba(0,0,0,0.05),
                                15px 10px 15px rgba(0,0,0,0.025);
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                font-size: .8rem;
                                color: var(--clr)
                            }
        
                            p{
                                display: flex;
                                justify-content: center;
        
                                
                
                                span{
                                    background: #4f2500;
                                border-radius: 25px;
                                padding: 5px;
                                font-size: .8rem;
                                color: white;
                                }
                
                                span:before{
                                    content: '';
                                    animation: anim infinite 30s;
                                }
                
                                @keyframes anim{
                                    0%{
                                        content: 'Mutemwa Leprosy Settlement was established in 1937 as a Leprosium.';
                                    }
                                    25%{
                                        content: 'In 1969, John Randal Bradburne (14 June 1921 - 05 September 1979) became the warden of the settlement.';
                                    }
                                    50%{
                                        content: 'He cares for the sick in the house.';
                                    }
                                    75%{
                                        content: 'Currently, Mutemwa Leprosy and Care Centre hold an average of 32 residents.';
                                    }
                                    100%{
                                        content: 'Mutemwa Settlement is in Mutoko a small town in Mashonaland East Province of Zimbabwe. It lies 143km from Harare.';
                                    }
                                }
                            }
            
                        }
                    }
            
                    .drop:nth-child(1){
                        border-radius: 75% 25% 44% 56% / 56% 32% 68% 44%;
                    }
                }
                
            }
    
            .vision{
                height: 100%;
                // background: url('../images/mountainCross-01.png');
                // background-size: cover;
                // background-repeat: no-repeat;
                background-position-y: 17%;
    
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                padding: 10px 0;
                gap:40px 60px;
    
                .drop{
                    position: relative;
                    width: 250px;
                    height: 350px;
                    background: #eff0f4;
                    box-shadow: inset 20px 20px 20px rgba(0,0,0,0.05),
                    25px 35px 20px rgba(0,0,0,0.05),
                    25px 30px 30px rgba(0,0,0,0.05),
                    inset -20px -20px 25px rgb(255,255,255);
                    transition: 0.5s ease-in-out;
                    cursor: pointer;
                    color:#4f2500;
        
                    .content{
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        text-align: center;
                        padding: 40px;
                        gap: 15px;
    
                        .svg{
                            img{
                                width: 50px;
                                height: 50px;
                            }
                        }
        
                        h4{
                            position: relative;
                            width: 200px;
                            height: 120px;
                            background: #eff0f4;
                            border-radius: 50%;
                            box-shadow: inset 2px 5px 10px rgba(0,0,0,0.1),
                            inset -2px -5px 10px rgba(255,255,255,0.1),
                            15px 15px 10px rgba(0,0,0,0.05),
                            15px 10px 15px rgba(0,0,0,0.025);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-size: 1.1rem;
                            color: var(--clr)
                        }
    
                        p{
                            display: flex;
                            justify-content: center;
    
                        
                        }
        
                    }
                }
    
                .drop:nth-child(3){
                    position: relative;
                    width: 350px;
                    height: 650px;
                    background: #eff0f4;
                    box-shadow: inset 20px 20px 20px rgba(0,0,0,0.1),
                    25px 35px 20px rgba(0,0,0,0.05),
                    25px 30px 30px rgba(0,0,0,0.05),
                    inset -20px -20px 25px rgba(255,255,255,1);
                    transition: 0.5s ease-in-out;
                    cursor: pointer;
                    color:#4f2500;
        
                    .content{
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        text-align: center;
                        padding: 40px;
                        gap: 15px;
        
                        h4{
                            position: relative;
                            width: 200px;
                            height: 120px;
                            background: #eff0f4;
                            border-radius: 50%;
                            box-shadow: inset 2px 5px 10px rgba(0,0,0,0.1),
                            inset -2px -5px 10px rgba(255,255,255,1),
                            15px 15px 10px rgba(0,0,0,0.05),
                            15px 10px 15px rgba(0,0,0,0.025);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-size: 1.1rem;
                            color: var(--clr)
                        }
    
                        p{
                            display: flex;
                            justify-content: center;
    
                        
                        }
        
                    }
                }
    
    
                .drop:nth-child(1):hover{
                    border-radius: 50%;
                }
    
                .drop:nth-child(2):hover{
                    border-radius: 50%;
                }
    
                .drop:nth-child(3):hover{
                    border-radius: 25%;
                }
            }
    
            .bradburne-cover{
                
                display: flex;
                // justify-content: center;
                // align-items: center;
    
                .bradburne{
                    // margin: 5em auto;
                    width: 80vw !important;
            
                    h5{
                        font-size: 1.5rem !important;
                        color: #4f2500;
                        text-align: center;
                        margin-bottom: 40px !important;
                    }
            
                    .john{
                        
                        width: 100% !important;
                        //margin-top: 10px !important;
                        transform:none !important;
                        padding-left: 0 !important;
                        padding-right: 0 !important;
                        border: none !important;
                    }
                    
                    .my-div{
                        border: 3px solid #4f2500 !important;
                        border-radius: 1em !important;
                        padding: 1.5em !important;
                        box-shadow: 0 0 0.5em #0d71fc !important;
                    }
            
                    .my-div::before{
                        top: -0.5em !important;
                        left: 50% !important;
                        transform: translateX(-50%) !important;
                    }
            
                    .john::before{
                        width: 3px !important;
                        height: 4em !important;
                        top:-2em !important;
                        left:50% !important;
                        transform: translateX(-50%) !important;
                    }
                }
               
            }
        }
    
        .landing{
            display: flex;
            flex-direction: column;
            position: relative;
    
            .welcome{
                display: flex;
                justify-content: center;
                
                .option{
                    overflow: hidden;
                }
    
                .option li{
                    text-align: center;
                    font-size: 1.1rem !important;
                    letter-spacing: 1px;
                    position: relative;
                    top: 0;
                }     
    
                @keyframes animate{
                    40%, 60%{
                        left:100%;
                    }
    
                    100%{
                        left:100%;
                    }
                }
            }
    
            .d-design{
                height: 100vh;
                // background: url('../images/johnbradburn.jpg');
                // background-size: cover;
                // background-repeat: no-repeat;
                display: flex;
                justify-content: center;
                position: relative;
    
                .invitation-cover{
                        position: absolute;
                        margin: 10px auto;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        left: 0;
                        height: 200px;
                        width: 100%;
                        box-shadow:0px 3px 10px 5px #c7bcb3;
    
                        
                    .invitation{
                        padding: 10px;
                        //overflow: scroll;
                        display: flex;
                        flex-direction: column;
                        justify-content: start;
                        width: 90%;
    
                        .message{
                            border-bottom: 1px solid white;
                            p{
                                color: white !important;
                                
                                padding: 5px;
                            }
        
                        }
        
                        .message:hover{
                            cursor: pointer;
                            background: #4f2500;
                            color: white !important;
                            transition: 1s;
                        }
                    }
                    
    
                    h4{
                        text-transform: uppercase;
                    }

                    .btn-viewMessage{
                        margin-top: 10px;
                        height: 50px;
                        width: 100%;
                        padding: 10px 5px;
                        background-color: #4f2500;
                        cursor: pointer;
                        border-radius: 5px;
                        border: 1px solid transparent;
                    }
    
                    button a{
                        margin-top: 10px;
                        width: 160px;
                        padding: 10px 5px;
                        //background-color: #4f2500;
                        color: #fff !important;
                        cursor: pointer;
                        border-radius: 5px;     
                    }
    
                    .btn-viewMessage:hover{
                        border: 1px solid #4f2500;
                        background-color: white;
                        transition: 1s;
    
                        a{
                            color: #4f2500 !important;
                        }
                    }
                }
    
                
                .brad-background{
                    //width: 60%;
                    height: 80vh;
                    // background: url('../images/johnbradburn.jpg');
                    // background-size: cover;
                    // background-repeat: no-repeat;
                    background-position-y: 20%;
    
                    display: flex;
                    justify-content: center;
                    align-items: end;
                    flex-wrap: wrap;
                    padding: 10px 0;
                    gap:40px 60px;
    
                    .drop{
                        position: relative;
                        width: 350px;
                        height: 350px;
                        box-shadow: inset 20px 20px 20px rgba(0,0,0,0.05),
                        25px 35px 20px rgba(0,0,0,0.05),
                        25px 30px 30px rgba(0,0,0,0.05),
                        inset -20px -20px 25px rgba(255,255,255,0.9);
                        transition: 0.5s ease-in-out;
                        cursor: pointer;
                        color:#4f2500;
            
                        .content{
                            position: relative;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-direction: column;
                            text-align: center;
                            padding: 40px;
                            gap: 15px;
            
                            h4{
                                position: relative;
                                width: 170px;
                                height: 120px;
                                background: #eff0f4;
                                border-radius: 50%;
                                box-shadow: inset 2px 5px 10px rgba(0,0,0,0.1),
                                inset -2px -5px 10px rgba(255,255,255,1),
                                15px 15px 10px rgba(0,0,0,0.05),
                                15px 10px 15px rgba(0,0,0,0.025);
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                font-size: .8rem;
                                color: var(--clr)
                            }
        
                            p{
                                display: flex;
                                justify-content: center;
        
                                
                
                                span{
                                    background: #4f2500;
                                border-radius: 25px;
                                padding: 5px;
                                font-size: .8rem;
                                color: white;
                                }
                
                                span:before{
                                    content: '';
                                    animation: anim infinite 30s;
                                }
                
                                @keyframes anim{
                                    0%{
                                        content: 'Mutemwa Leprosy Settlement was established in 1937 as a Leprosium.';
                                    }
                                    25%{
                                        content: 'In 1969, John Randal Bradburne (14 June 1921 - 05 September 1979) became the warden of the settlement.';
                                    }
                                    50%{
                                        content: 'He cares for the sick in the house.';
                                    }
                                    75%{
                                        content: 'Currently, Mutemwa Leprosy and Care Centre hold an average of 32 residents.';
                                    }
                                    100%{
                                        content: 'Mutemwa Settlement is in Mutoko a small town in Mashonaland East Province of Zimbabwe. It lies 143km from Harare.';
                                    }
                                }
                            }
            
                        }
                    }
            
                    .drop:nth-child(1){
                        border-radius: 75% 25% 44% 56% / 56% 32% 68% 44%;
                    }
                }
                
            }
    
            .vision{
                height: 100%;
                // background: url('../images/mountainCross-01.png');
                // background-size: cover;
                // background-repeat: no-repeat;
                background-position-y: 17%;
    
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                padding: 10px 0;
                gap:40px 60px;
    
                .drop{
                    position: relative;
                    width: 250px;
                    height: 350px;
                    background: #eff0f4;
                    box-shadow: inset 20px 20px 20px rgba(0,0,0,0.05),
                    25px 35px 20px rgba(0,0,0,0.05),
                    25px 30px 30px rgba(0,0,0,0.05),
                    inset -20px -20px 25px rgb(255,255,255);
                    transition: 0.5s ease-in-out;
                    cursor: pointer;
                    color:#4f2500;
        
                    .content{
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        text-align: center;
                        padding: 40px;
                        gap: 15px;
    
                        .svg{
                            img{
                                width: 50px;
                                height: 50px;
                            }
                        }
        
                        h4{
                            position: relative;
                            width: 200px;
                            height: 120px;
                            background: #eff0f4;
                            border-radius: 50%;
                            box-shadow: inset 2px 5px 10px rgba(0,0,0,0.1),
                            inset -2px -5px 10px rgba(255,255,255,0.1),
                            15px 15px 10px rgba(0,0,0,0.05),
                            15px 10px 15px rgba(0,0,0,0.025);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-size: 1.1rem;
                            color: var(--clr)
                        }
    
                        p{
                            display: flex;
                            justify-content: center;
    
                        
                        }
        
                    }
                }
    
                .drop:nth-child(3){
                    position: relative;
                    width: 350px;
                    height: 650px;
                    background: #eff0f4;
                    box-shadow: inset 20px 20px 20px rgba(0,0,0,0.1),
                    25px 35px 20px rgba(0,0,0,0.05),
                    25px 30px 30px rgba(0,0,0,0.05),
                    inset -20px -20px 25px rgba(255,255,255,1);
                    transition: 0.5s ease-in-out;
                    cursor: pointer;
                    color:#4f2500;
        
                    .content{
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        text-align: center;
                        padding: 40px;
                        gap: 15px;
        
                        h4{
                            position: relative;
                            width: 200px;
                            height: 120px;
                            background: #eff0f4;
                            border-radius: 50%;
                            box-shadow: inset 2px 5px 10px rgba(0,0,0,0.1),
                            inset -2px -5px 10px rgba(255,255,255,1),
                            15px 15px 10px rgba(0,0,0,0.05),
                            15px 10px 15px rgba(0,0,0,0.025);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-size: 1.1rem;
                            color: var(--clr)
                        }
    
                        p{
                            display: flex;
                            justify-content: center;
    
                        
                        }
        
                    }
                }
    
    
                .drop:nth-child(1):hover{
                    border-radius: 50%;
                }
    
                .drop:nth-child(2):hover{
                    border-radius: 50%;
                }
    
                .drop:nth-child(3):hover{
                    border-radius: 25%;
                }
            }
    
            .bradburne-cover{
                
                display: flex;
                // justify-content: center;
                // align-items: center;
    
                .bradburne{
                    // margin: 5em auto;
                    width: 80vw !important;
            
                    h5{
                        font-size: 1.5rem !important;
                        color: #4f2500;
                        text-align: center;
                        margin-bottom: 40px !important;
                    }
            
                    .john{
                        
                        width: 100% !important;
                        //margin-top: 10px !important;
                        transform:none !important;
                        padding-left: 0 !important;
                        padding-right: 0 !important;
                        border: none !important;
                    }
                    
                    .my-div{
                        border: 3px solid #4f2500 !important;
                        border-radius: 1em !important;
                        padding: 1.5em !important;
                        box-shadow: 0 0 0.5em #0d71fc !important;
                    }
            
                    .my-div::before{
                        top: -0.5em !important;
                        left: 50% !important;
                        transform: translateX(-50%) !important;
                    }
            
                    .john::before{
                        width: 3px !important;
                        height: 4em !important;
                        top:-2em !important;
                        left:50% !important;
                        transform: translateX(-50%) !important;
                    }
                }
               
            }
        }
    
        
    }
}

@media screen and (max-width:400px){
    .home-cover{
        //background: rgb(79,37,0);
        //background: linear-gradient(90deg, rgba(79,37,0,1) 0%, rgba(255,255,255,1) 10%, rgba(79,37,0,1) 20%, rgba(79,37,0,1) 35%, rgba(79,37,0,1) 50%, rgba(79,37,0,1) 65%, rgba(79,37,0,1) 80%, rgba(220,211,204,1) 90%, rgba(79,37,0,1) 100%);
        background: rgb(237,238,235);
        background: linear-gradient(90deg, rgba(237,238,235,1) 17%, rgba(237,238,235,1) 44%, rgba(237,238,235,1) 66%, rgba(237,238,235,1) 100%);
        background-repeat: no-repeat;
        color: #4f2500;
        //padding: 10px;
        .banner1, .banner2, .banner3, .banner5{
            height: 100vh;
            width: 100%;
            position: absolute;
            left: 0;
            right: 0;
            z-index: 1;
    
            img{
                position: absolute;
                width: 100%;
    
            }
    
            .text-description{
                width: 100px;
                position: absolute;
                top: 10px;
                left: 10;
                animation: textup 12s linear infinite;
                transform: translateY(100px);
    
                h1{
                    margin-bottom: 5px;
                }
    
                span{
                    position: absolute;
                    height: 2px;
                    left: 0;
                    background-color: #4f2500;
                    width: 100px;
                }
    
                p{
                    font-size: 14px;
                    line-height: 18px;
                    margin-top: 20px;
                }
    
                .btn-register{
                    margin-top: 5px;
                    padding: 5px;
                    font-size: 13px;
                    background-color: #4f2500;
                    color: white;
    
                    a{
                        list-style: none;
                        color: #c7bcb3;
                    }
                }
    
                .btn-register:hover{
                    background-color: #fff;
                    color: #000;
                    transition: 2s;
    
                    a{
                        list-style: none;
                        color: #000;
                    }
                }
                 
            }
        }
    
        .banner4{
            height: 300px;
            width: 500px;
            position: absolute;
            left: 0;
            right: 0;
            z-index: 2;
            margin-bottom: 20px;
    
            img{
                position: absolute;
                height: 300px;
                width: 100%;
                left: 50%;
            }
    
            .text-description{
                width: 100px;
                position: absolute;
                top: 20px;
                left: 10;
                animation: textup 12s linear infinite;
                transform: translateY(100px);
    
                h1{
                    margin-bottom: 5px;
                }
    
                span{
                    position: absolute;
                    height: 2px;
                    left: 0;
                    background-color: #4f2500;
                    width: 100px;
                }
    
                p{
                    font-size: 14px;
                    line-height: 18px;
                    margin-top: 20px;
                }
    
                .btn-register{
                    margin-top: 5px;
                    padding: 5px;
                    font-size: 13px;
                    background-color: #4f2500;
                    color: white;
    
                    a{
                        list-style: none;
                        color: #c7bcb3;
                    }
                }
    
                .btn-register:hover{
                    background-color: #fff;
                    color: #000;
                    transition: 2s;
    
                    a{
                        list-style: none;
                        color: #000;
                    }
                }
                 
            }
        }
        .landing{
            display: flex;
            flex-direction: column;
            position: relative;
    
            .welcome{
                display: flex;
                justify-content: center;
                
                .option{
                    overflow: hidden;
                }
    
                .option li{
                    text-align: center;
                    font-size: 1.1rem !important;
                    letter-spacing: 1px;
                    position: relative;
                    top: 0;
                }     
    
                @keyframes animate{
                    40%, 60%{
                        left:100%;
                    }
    
                    100%{
                        left:100%;
                    }
                }
            }
    
            .d-design{
                height: 100vh;
                // background: url('../images/johnbradburn.jpg');
                // background-size: cover;
                // background-repeat: no-repeat;
                display: flex;
                justify-content: center;
                position: relative;
    
                .invitation-cover{
                        position: absolute;
                        margin: 10px auto;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        left: 0;
                        height: 200px;
                        width: 100%;
                        box-shadow:0px 3px 10px 5px #c7bcb3;
    
                        
                    .invitation{
                        padding: 10px;
                        //overflow: scroll;
                        display: flex;
                        flex-direction: column;
                        justify-content: start;
                        width: 90%;
    
                        .message{
                            border-bottom: 1px solid white;
                            p{
                                color: white !important;
                                
                                padding: 5px;
                            }
        
                        }
        
                        .message:hover{
                            cursor: pointer;
                            background: #4f2500;
                            color: white !important;
                            transition: 1s;
                        }
                    }
                    
    
                    h4{
                        text-transform: uppercase;
                    }

                    .btn-viewMessage{
                        margin-top: 10px;
                        height: 50px;
                        width: 100%;
                        padding: 10px 5px;
                        background-color: #4f2500;
                        cursor: pointer;
                        border-radius: 5px;
                        border: 1px solid transparent;
                    }
    
                    button a{
                        margin-top: 10px;
                        width: 160px;
                        padding: 10px 5px;
                        //background-color: #4f2500;
                        color: #fff !important;
                        cursor: pointer;
                        border-radius: 5px;     
                    }
    
                    .btn-viewMessage:hover{
                        border: 1px solid #4f2500;
                        background-color: white;
                        transition: 1s;
    
                        a{
                            color: #4f2500 !important;
                        }
                    }
                }
    
                
                .brad-background{
                    //width: 60%;
                    height: 80vh;
                    // background: url('../images/johnbradburn.jpg');
                    // background-size: cover;
                    // background-repeat: no-repeat;
                    background-position-y: 20%;
    
                    display: flex;
                    justify-content: center;
                    align-items: end;
                    flex-wrap: wrap;
                    padding: 10px 0;
                    gap:40px 60px;
    
                    .drop{
                        position: relative;
                        width: 350px;
                        height: 350px;
                        box-shadow: inset 20px 20px 20px rgba(0,0,0,0.05),
                        25px 35px 20px rgba(0,0,0,0.05),
                        25px 30px 30px rgba(0,0,0,0.05),
                        inset -20px -20px 25px rgba(255,255,255,0.9);
                        transition: 0.5s ease-in-out;
                        cursor: pointer;
                        color:#4f2500;
            
                        .content{
                            position: relative;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-direction: column;
                            text-align: center;
                            padding: 40px;
                            gap: 15px;
            
                            h4{
                                position: relative;
                                width: 170px;
                                height: 120px;
                                background: #eff0f4;
                                border-radius: 50%;
                                box-shadow: inset 2px 5px 10px rgba(0,0,0,0.1),
                                inset -2px -5px 10px rgba(255,255,255,1),
                                15px 15px 10px rgba(0,0,0,0.05),
                                15px 10px 15px rgba(0,0,0,0.025);
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                font-size: .8rem;
                                color: var(--clr)
                            }
        
                            p{
                                display: flex;
                                justify-content: center;
        
                                
                
                                span{
                                    background: #4f2500;
                                border-radius: 25px;
                                padding: 5px;
                                font-size: .8rem;
                                color: white;
                                }
                
                                span:before{
                                    content: '';
                                    animation: anim infinite 30s;
                                }
                
                                @keyframes anim{
                                    0%{
                                        content: 'Mutemwa Leprosy Settlement was established in 1937 as a Leprosium.';
                                    }
                                    25%{
                                        content: 'In 1969, John Randal Bradburne (14 June 1921 - 05 September 1979) became the warden of the settlement.';
                                    }
                                    50%{
                                        content: 'He cares for the sick in the house.';
                                    }
                                    75%{
                                        content: 'Currently, Mutemwa Leprosy and Care Centre hold an average of 32 residents.';
                                    }
                                    100%{
                                        content: 'Mutemwa Settlement is in Mutoko a small town in Mashonaland East Province of Zimbabwe. It lies 143km from Harare.';
                                    }
                                }
                            }
            
                        }
                    }
            
                    .drop:nth-child(1){
                        border-radius: 75% 25% 44% 56% / 56% 32% 68% 44%;
                    }
                }
                
            }
    
            .vision{
                height: 100%;
                // background: url('../images/mountainCross-01.png');
                // background-size: cover;
                // background-repeat: no-repeat;
                background-position-y: 17%;
    
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                padding: 10px 0;
                gap:40px 60px;
    
                .drop{
                    position: relative;
                    width: 250px;
                    height: 350px;
                    background: #eff0f4;
                    box-shadow: inset 20px 20px 20px rgba(0,0,0,0.05),
                    25px 35px 20px rgba(0,0,0,0.05),
                    25px 30px 30px rgba(0,0,0,0.05),
                    inset -20px -20px 25px rgb(255,255,255);
                    transition: 0.5s ease-in-out;
                    cursor: pointer;
                    color:#4f2500;
        
                    .content{
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        text-align: center;
                        padding: 40px;
                        gap: 15px;
    
                        .svg{
                            img{
                                width: 50px;
                                height: 50px;
                            }
                        }
        
                        h4{
                            position: relative;
                            width: 200px;
                            height: 120px;
                            background: #eff0f4;
                            border-radius: 50%;
                            box-shadow: inset 2px 5px 10px rgba(0,0,0,0.1),
                            inset -2px -5px 10px rgba(255,255,255,0.1),
                            15px 15px 10px rgba(0,0,0,0.05),
                            15px 10px 15px rgba(0,0,0,0.025);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-size: 1.1rem;
                            color: var(--clr)
                        }
    
                        p{
                            display: flex;
                            justify-content: center;
    
                        
                        }
        
                    }
                }
    
                .drop:nth-child(3){
                    position: relative;
                    width: 350px;
                    height: 650px;
                    background: #eff0f4;
                    box-shadow: inset 20px 20px 20px rgba(0,0,0,0.1),
                    25px 35px 20px rgba(0,0,0,0.05),
                    25px 30px 30px rgba(0,0,0,0.05),
                    inset -20px -20px 25px rgba(255,255,255,1);
                    transition: 0.5s ease-in-out;
                    cursor: pointer;
                    color:#4f2500;
        
                    .content{
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        text-align: center;
                        padding: 40px;
                        gap: 15px;
        
                        h4{
                            position: relative;
                            width: 200px;
                            height: 120px;
                            background: #eff0f4;
                            border-radius: 50%;
                            box-shadow: inset 2px 5px 10px rgba(0,0,0,0.1),
                            inset -2px -5px 10px rgba(255,255,255,1),
                            15px 15px 10px rgba(0,0,0,0.05),
                            15px 10px 15px rgba(0,0,0,0.025);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-size: 1.1rem;
                            color: var(--clr)
                        }
    
                        p{
                            display: flex;
                            justify-content: center;
    
                        
                        }
        
                    }
                }
    
    
                .drop:nth-child(1):hover{
                    border-radius: 50%;
                }
    
                .drop:nth-child(2):hover{
                    border-radius: 50%;
                }
    
                .drop:nth-child(3):hover{
                    border-radius: 25%;
                }
            }
    
            .bradburne-cover{
                
                display: flex;
                // justify-content: center;
                // align-items: center;
    
                .bradburne{
                    // margin: 5em auto;
                    width: 80vw !important;
            
                    h5{
                        font-size: 1.5rem !important;
                        color: #4f2500;
                        text-align: center;
                        margin-bottom: 40px !important;
                    }
            
                    .john{
                        
                        width: 100% !important;
                        //margin-top: 10px !important;
                        transform:none !important;
                        padding-left: 0 !important;
                        padding-right: 0 !important;
                        border: none !important;
                    }
                    
                    .my-div{
                        border: 3px solid #4f2500 !important;
                        border-radius: 1em !important;
                        padding: 1.5em !important;
                        box-shadow: 0 0 0.5em #0d71fc !important;
                    }
            
                    .my-div::before{
                        top: -0.5em !important;
                        left: 50% !important;
                        transform: translateX(-50%) !important;
                    }
            
                    .john::before{
                        width: 3px !important;
                        height: 4em !important;
                        top:-2em !important;
                        left:50% !important;
                        transform: translateX(-50%) !important;
                    }
                }
               
            }
        }

        .landing{
            display: flex;
            flex-direction: column;
            position: relative;
    
            .welcome{
                display: flex;
                justify-content: center;
                
                .option{
                    overflow: hidden;
                }
    
                .option li{
                    text-align: center;
                    font-size: 1.1rem !important;
                    letter-spacing: 1px;
                    position: relative;
                    top: 0;
                }     
    
                @keyframes animate{
                    40%, 60%{
                        left:100%;
                    }
    
                    100%{
                        left:100%;
                    }
                }
            }
    
            .d-design{
                height: 100vh;
                // background: url('../images/johnbradburn.jpg');
                // background-size: cover;
                // background-repeat: no-repeat;
                display: flex;
                justify-content: center;
                position: relative;
    
                .invitation-cover{
                        position: absolute;
                        margin: 10px auto;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        left: 0;
                        height: 200px;
                        width: 100%;
                        box-shadow:0px 3px 10px 5px #c7bcb3;
    
                        
                    .invitation{
                        padding: 10px;
                        //overflow: scroll;
                        display: flex;
                        flex-direction: column;
                        justify-content: start;
                        width: 90%;
    
                        .message{
                            border-bottom: 1px solid white;
                            p{
                                color: white !important;
                                
                                padding: 5px;
                            }
        
                        }
        
                        .message:hover{
                            cursor: pointer;
                            background: #4f2500;
                            color: white !important;
                            transition: 1s;
                        }
                    }
                    
    
                    h4{
                        text-transform: uppercase;
                    }

                    .btn-viewMessage{
                        margin-top: 10px;
                        height: 50px;
                        width: 100%;
                        padding: 10px 5px;
                        background-color: #4f2500;
                        cursor: pointer;
                        border-radius: 5px;
                        border: 1px solid transparent;
                    }
    
                    button a{
                        margin-top: 10px;
                        width: 160px;
                        padding: 10px 5px;
                        //background-color: #4f2500;
                        color: #fff !important;
                        cursor: pointer;
                        border-radius: 5px;     
                    }
    
                    .btn-viewMessage:hover{
                        border: 1px solid #4f2500;
                        background-color: white;
                        transition: 1s;
    
                        a{
                            color: #4f2500 !important;
                        }
                    }
                }
    
                
                .brad-background{
                    //width: 60%;
                    height: 80vh;
                    // background: url('../images/johnbradburn.jpg');
                    // background-size: cover;
                    // background-repeat: no-repeat;
                    background-position-y: 20%;
    
                    display: flex;
                    justify-content: center;
                    align-items: end;
                    flex-wrap: wrap;
                    padding: 10px 0;
                    gap:40px 60px;
    
                    .drop{
                        position: relative;
                        width: 280px;
                        height: 280px;
                        box-shadow: inset 20px 20px 20px rgba(0,0,0,0.05),
                        25px 35px 20px rgba(0,0,0,0.05),
                        25px 30px 30px rgba(0,0,0,0.05),
                        inset -20px -20px 25px rgba(255,255,255,0.9);
                        transition: 0.5s ease-in-out;
                        cursor: pointer;
                        color:#4f2500;
            
                        .content{
                            position: relative;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-direction: column;
                            text-align: center;
                            padding: 40px;
                            gap: 15px;
            
                            h4{
                                position: relative;
                                width: 140px;
                                height: 100px;
                                background: #eff0f4;
                                border-radius: 50%;
                                box-shadow: inset 2px 5px 10px rgba(0,0,0,0.1),
                                inset -2px -5px 10px rgba(255,255,255,1),
                                15px 15px 10px rgba(0,0,0,0.05),
                                15px 10px 15px rgba(0,0,0,0.025);
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                font-size: .6rem;
                                color: var(--clr)
                            }
        
                            p{
                                display: flex;
                                justify-content: center;
        
                                
                
                                span{
                                    background: #4f2500;
                                border-radius: 25px;
                                padding: 5px;
                                font-size: .6rem;
                                color: white;
                                }
                
                                span:before{
                                    content: '';
                                    animation: anim infinite 30s;
                                }
                
                                @keyframes anim{
                                    0%{
                                        content: 'Mutemwa Leprosy Settlement was established in 1937 as a Leprosium.';
                                    }
                                    25%{
                                        content: 'In 1969, John Randal Bradburne (14 June 1921 - 05 September 1979) became the warden of the settlement.';
                                    }
                                    50%{
                                        content: 'He cares for the sick in the house.';
                                    }
                                    75%{
                                        content: 'Currently, Mutemwa Leprosy and Care Centre hold an average of 32 residents.';
                                    }
                                    100%{
                                        content: 'Mutemwa Settlement is in Mutoko a small town in Mashonaland East Province of Zimbabwe. It lies 143km from Harare.';
                                    }
                                }
                            }
            
                        }
                    }
            
                    .drop:nth-child(1){
                        border-radius: 75% 25% 44% 56% / 56% 32% 68% 44%;
                    }
                }
                
            }
    
            .vision{
                height: 100%;
                // background: url('../images/mountainCross-01.png');
                // background-size: cover;
                // background-repeat: no-repeat;
                background-position-y: 17%;
    
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                padding: 10px 0;
                gap:40px 60px;
    
                .drop{
                    position: relative;
                    width: 250px;
                    height: 350px;
                    background: #eff0f4;
                    box-shadow: inset 20px 20px 20px rgba(0,0,0,0.05),
                    25px 35px 20px rgba(0,0,0,0.05),
                    25px 30px 30px rgba(0,0,0,0.05),
                    inset -20px -20px 25px rgb(255,255,255);
                    transition: 0.5s ease-in-out;
                    cursor: pointer;
                    color:#4f2500;
        
                    .content{
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        text-align: center;
                        padding: 40px;
                        gap: 15px;
    
                        .svg{
                            img{
                                width: 50px;
                                height: 50px;
                            }
                        }
        
                        h4{
                            position: relative;
                            width: 200px;
                            height: 120px;
                            background: #eff0f4;
                            border-radius: 50%;
                            box-shadow: inset 2px 5px 10px rgba(0,0,0,0.1),
                            inset -2px -5px 10px rgba(255,255,255,0.1),
                            15px 15px 10px rgba(0,0,0,0.05),
                            15px 10px 15px rgba(0,0,0,0.025);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-size: 1.1rem;
                            color: var(--clr)
                        }
    
                        p{
                            display: flex;
                            justify-content: center;
    
                        
                        }
        
                    }
                }
    
                .drop:nth-child(3){
                    position: relative;
                    width: 350px;
                    height: 650px;
                    background: #eff0f4;
                    box-shadow: inset 20px 20px 20px rgba(0,0,0,0.1),
                    25px 35px 20px rgba(0,0,0,0.05),
                    25px 30px 30px rgba(0,0,0,0.05),
                    inset -20px -20px 25px rgba(255,255,255,1);
                    transition: 0.5s ease-in-out;
                    cursor: pointer;
                    color:#4f2500;
        
                    .content{
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        text-align: center;
                        padding: 40px;
                        gap: 15px;
        
                        h4{
                            position: relative;
                            width: 200px;
                            height: 120px;
                            background: #eff0f4;
                            border-radius: 50%;
                            box-shadow: inset 2px 5px 10px rgba(0,0,0,0.1),
                            inset -2px -5px 10px rgba(255,255,255,1),
                            15px 15px 10px rgba(0,0,0,0.05),
                            15px 10px 15px rgba(0,0,0,0.025);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-size: 1.1rem;
                            color: var(--clr)
                        }
    
                        p{
                            display: flex;
                            justify-content: center;
    
                        
                        }
        
                    }
                }
    
    
                .drop:nth-child(1):hover{
                    border-radius: 50%;
                }
    
                .drop:nth-child(2):hover{
                    border-radius: 50%;
                }
    
                .drop:nth-child(3):hover{
                    border-radius: 25%;
                }
            }
    
            .bradburne-cover{
                
                display: flex;
                // justify-content: center;
                // align-items: center;
    
                .bradburne{
                    // margin: 5em auto;
                    width: 80vw !important;
            
                    h5{
                        font-size: 1.5rem !important;
                        color: #4f2500;
                        text-align: center;
                        margin-bottom: 40px !important;
                    }
            
                    .john{
                        
                        width: 100% !important;
                        //margin-top: 10px !important;
                        transform:none !important;
                        padding-left: 0 !important;
                        padding-right: 0 !important;
                        border: none !important;
                    }
                    
                    .my-div{
                        border: 3px solid #4f2500 !important;
                        border-radius: 1em !important;
                        padding: 1.5em !important;
                        box-shadow: 0 0 0.5em #0d71fc !important;
                    }
            
                    .my-div::before{
                        top: -0.5em !important;
                        left: 50% !important;
                        transform: translateX(-50%) !important;
                    }
            
                    .john::before{
                        width: 3px !important;
                        height: 4em !important;
                        top:-2em !important;
                        left:50% !important;
                        transform: translateX(-50%) !important;
                    }
                }
               
            }
        }
    
        
    }   
}

@media screen and (max-width:320px){
    .home-cover{
        //background: rgb(79,37,0);
        //background: linear-gradient(90deg, rgba(79,37,0,1) 0%, rgba(255,255,255,1) 10%, rgba(79,37,0,1) 20%, rgba(79,37,0,1) 35%, rgba(79,37,0,1) 50%, rgba(79,37,0,1) 65%, rgba(79,37,0,1) 80%, rgba(220,211,204,1) 90%, rgba(79,37,0,1) 100%);
        background: rgb(237,238,235);
        background: linear-gradient(90deg, rgba(237,238,235,1) 17%, rgba(237,238,235,1) 44%, rgba(237,238,235,1) 66%, rgba(237,238,235,1) 100%);
        background-repeat: no-repeat;
        color: #4f2500;
        //padding: 10px;
    
        .landing{
            display: flex;
            flex-direction: column;
            position: relative;
    
            .welcome{
                display: flex;
                justify-content: center;
                
                .option{
                    overflow: hidden;
                }
    
                .option li{
                    text-align: center;
                    font-size: 1.1rem !important;
                    letter-spacing: 1px;
                    position: relative;
                    top: 0;
                }     
    
                @keyframes animate{
                    40%, 60%{
                        left:100%;
                    }
    
                    100%{
                        left:100%;
                    }
                }
            }
    
            .d-design{
                height: 100vh;
                // background: url('../images/johnbradburn.jpg');
                // background-size: cover;
                // background-repeat: no-repeat;
                display: flex;
                justify-content: center;
                position: relative;
    
                .invitation-cover{
                        position: absolute;
                        margin: 10px auto;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        left: 0;
                        height: 150px;
                        width: 100%;
                        box-shadow:0px 3px 10px 5px #c7bcb3;
    
                        
                    .invitation{
                        padding: 10px;
                        //overflow: scroll;
                        display: flex;
                        flex-direction: column;
                        justify-content: start;
                        width: 90%;
    
                        .message{
                            border-bottom: 1px solid white;
                            p{
                                color: white !important;
                                
                                padding: 5px;
                            }
        
                        }
        
                        .message:hover{
                            cursor: pointer;
                            background: #4f2500;
                            color: white !important;
                            transition: 1s;
                        }
                    }
                    
    
                    h4{
                        text-transform: uppercase;
                    }

                    .btn-viewMessage{
                        margin-top: 10px;
                        height: 50px;
                        width: 100%;
                        padding: 10px 5px;
                        background-color: #4f2500;
                        cursor: pointer;
                        border-radius: 5px;
                        border: 1px solid transparent;
                    }
    
                    button a{
                        margin-top: 10px;
                        width: 160px;
                        padding: 10px 5px;
                        //background-color: #4f2500;
                        color: #fff !important;
                        cursor: pointer;
                        border-radius: 5px;     
                    }
    
                    .btn-viewMessage:hover{
                        border: 1px solid #4f2500;
                        background-color: white;
                        transition: 1s;
    
                        a{
                            color: #4f2500 !important;
                        }
                    }
                }
    
                
                .brad-background{
                    //width: 60%;
                    height: 100vh;
                    // background: url('../images/johnbradburn.jpg');
                    // background-size: cover;
                    // background-repeat: no-repeat;
                    background-position-y: 20%;
    
                    display: flex;
                    justify-content: center;
                    align-items: end;
                    flex-wrap: wrap;
                    padding: 10px 0;
                    gap:40px 60px;
    
                    .drop{
                        position: relative;
                        width: 280px;
                        height: 280px;
                        box-shadow: inset 20px 20px 20px rgba(0,0,0,0.05),
                        25px 35px 20px rgba(0,0,0,0.05),
                        25px 30px 30px rgba(0,0,0,0.05),
                        inset -20px -20px 25px rgba(255,255,255,0.9);
                        transition: 0.5s ease-in-out;
                        cursor: pointer;
                        color:#4f2500;
            
                        .content{
                            position: relative;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-direction: column;
                            text-align: center;
                            padding: 40px;
                            gap: 15px;
            
                            h4{
                                position: relative;
                                width: 140px;
                                height: 100px;
                                background: #eff0f4;
                                border-radius: 50%;
                                box-shadow: inset 2px 5px 10px rgba(0,0,0,0.1),
                                inset -2px -5px 10px rgba(255,255,255,1),
                                15px 15px 10px rgba(0,0,0,0.05),
                                15px 10px 15px rgba(0,0,0,0.025);
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                font-size: .6rem;
                                color: var(--clr)
                            }
        
                            p{
                                display: flex;
                                justify-content: center;
        
                                
                
                                span{
                                    background: #4f2500;
                                border-radius: 25px;
                                padding: 5px;
                                font-size: .6rem;
                                color: white;
                                }
                
                                span:before{
                                    content: '';
                                    animation: anim infinite 30s;
                                }
                
                                @keyframes anim{
                                    0%{
                                        content: 'Mutemwa Leprosy Settlement was established in 1937 as a Leprosium.';
                                    }
                                    25%{
                                        content: 'In 1969, John Randal Bradburne (14 June 1921 - 05 September 1979) became the warden of the settlement.';
                                    }
                                    50%{
                                        content: 'He cares for the sick in the house.';
                                    }
                                    75%{
                                        content: 'Currently, Mutemwa Leprosy and Care Centre hold an average of 32 residents.';
                                    }
                                    100%{
                                        content: 'Mutemwa Settlement is in Mutoko a small town in Mashonaland East Province of Zimbabwe. It lies 143km from Harare.';
                                    }
                                }
                            }
            
                        }
                    }
            
                    .drop:nth-child(1){
                        border-radius: 75% 25% 44% 56% / 56% 32% 68% 44%;
                    }
                }
                
            }
    
            .vision{
                height: 100%;
                // background: url('../images/mountainCross-01.png');
                // background-size: cover;
                // background-repeat: no-repeat;
                background-position-y: 17%;
    
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                padding: 10px 0;
                gap:40px 60px;
    
                .drop{
                    position: relative;
                    width: 280px;
                    height: 500px;
                    background: #eff0f4;
                    box-shadow: inset 20px 20px 20px rgba(0,0,0,0.05),
                    25px 35px 20px rgba(0,0,0,0.05),
                    25px 30px 30px rgba(0,0,0,0.05),
                    inset -20px -20px 25px rgb(255,255,255);
                    transition: 0.5s ease-in-out;
                    cursor: pointer;
                    color:#4f2500;
        
                    .content{
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        text-align: center;
                        padding: 40px;
                        gap: 15px;
    
                        .svg{
                            img{
                                width: 50px;
                                height: 50px;
                            }
                        }
        
                        h4{
                            position: relative;
                            width: 200px;
                            height: 120px;
                            background: #eff0f4;
                            border-radius: 50%;
                            box-shadow: inset 2px 5px 10px rgba(0,0,0,0.1),
                            inset -2px -5px 10px rgba(255,255,255,0.1),
                            15px 15px 10px rgba(0,0,0,0.05),
                            15px 10px 15px rgba(0,0,0,0.025);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-size: 1.1rem;
                            color: var(--clr)
                        }
    
                        p{
                            display: flex;
                            justify-content: center;
    
                        
                        }
        
                    }
                }
    
                .drop:nth-child(3){
                    position: relative;
                    width: 280px;
                    height: 650px;
                    background: #eff0f4;
                    box-shadow: inset 20px 20px 20px rgba(0,0,0,0.1),
                    25px 35px 20px rgba(0,0,0,0.05),
                    25px 30px 30px rgba(0,0,0,0.05),
                    inset -20px -20px 25px rgba(255,255,255,1);
                    transition: 0.5s ease-in-out;
                    cursor: pointer;
                    color:#4f2500;
        
                    .content{
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        text-align: center;
                        padding: 40px;
                        gap: 15px;
        
                        h4{
                            position: relative;
                            width: 200px;
                            height: 120px;
                            background: #eff0f4;
                            border-radius: 50%;
                            box-shadow: inset 2px 5px 10px rgba(0,0,0,0.1),
                            inset -2px -5px 10px rgba(255,255,255,1),
                            15px 15px 10px rgba(0,0,0,0.05),
                            15px 10px 15px rgba(0,0,0,0.025);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-size: 1.1rem;
                            color: var(--clr)
                        }
    
                        p{
                            display: flex;
                            justify-content: center;
    
                        
                        }
        
                    }
                }
    
    
                .drop:nth-child(1):hover{
                    border-radius: 50%;
                }
    
                .drop:nth-child(2):hover{
                    border-radius: 50%;
                }
    
                .drop:nth-child(3):hover{
                    border-radius: 25%;
                }
            }
    
            .bradburne-cover{
                
                display: flex;
                // justify-content: center;
                // align-items: center;
    
                .bradburne{
                    // margin: 5em auto;
                    width: 80vw !important;
            
                    h5{
                        font-size: 1.5rem !important;
                        color: #4f2500;
                        text-align: center;
                        margin-bottom: 40px !important;
                    }
            
                    .john{
                        
                        width: 100% !important;
                        //margin-top: 10px !important;
                        transform:none !important;
                        padding-left: 0 !important;
                        padding-right: 0 !important;
                        border: none !important;
                    }
                    
                    .my-div{
                        border: 3px solid #4f2500 !important;
                        border-radius: 1em !important;
                        padding: 1.5em !important;
                        box-shadow: 0 0 0.5em #0d71fc !important;
                    }
            
                    .my-div::before{
                        top: -0.5em !important;
                        left: 50% !important;
                        transform: translateX(-50%) !important;
                    }
            
                    .john::before{
                        width: 3px !important;
                        height: 4em !important;
                        top:-2em !important;
                        left:50% !important;
                        transform: translateX(-50%) !important;
                    }
                }
               
            }
        }
    
        
    } 
}