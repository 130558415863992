.donation-cover{
    .image-top{
        height: 100px;
        background: url('../images/delivery_food.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position-y: 65%;

        h4{
            padding: 60px 10px 10px 10px;
            cursor: pointer;
            bottom: 0 !important;
            font-size: 1.5rem;
            color: #4f2500;
        }

    }

    .cover{
        
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        padding: 50px 0;
        gap:40px 60px;
        

        .drop{
            position: relative;
            width: 400px;
            height: 400px;
            box-shadow: inset 20px 20px 20px rgba(0,0,0,0.05),
            25px 35px 20px rgba(0,0,0,0.05),
            25px 30px 30px rgba(0,0,0,0.05),
            inset -20px -20px 25px rgba(255,255,255,0.9);
            transition: 0.5s ease-in-out;
            cursor: pointer;
            color:#4f2500;

            .content{
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                text-align: center;
                padding: 40px;
                gap: 15px;

                h4{
                    position: relative;
                    width: 160px;
                    height: 90px;
                    background: #eff0f4;
                    border-radius: 50%;
                    box-shadow: inset 2px 5px 10px rgba(0,0,0,0.1),
                    inset -2px -5px 10px rgba(255,255,255,1),
                    15px 15px 10px rgba(0,0,0,0.05),
                    15px 10px 15px rgba(0,0,0,0.025);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 1.1rem;
                    color: var(--clr)
                }

                .logo{
                    img{
                        width: 150px;
                        height: 75px;
                    }
                }

            }
        }

        .drop:nth-child(1){
            border-radius: 57% 43% 43% 57% / 43% 43% 57% 70%;
        }

        .drop:nth-child(2){
            border-radius: 61% 39% 52% 48% / 44% 59% 41% 56%;
        }

        .drop:nth-child(3){
            border-radius: 19% 81% 48% 52% / 72% 21% 79% 28%;
        }

        .drop:nth-child(4){
            border-radius: 36% 64% 35% 65% / 72% 21% 79% 28%;
        }

        .drop:hover{
            border-radius: 50%;
        }

        // .drop::before{
        //     content: '';
        //     position: absolute;
        //     top:50px;
        //     left:65px;  
        //     width: 35px;
        //     height: 35px;
        //     background: #4f2500;
        //     border-radius: 50%;
        //     opacity: 0.9;
        // }

        // .drop::after{
        //     content: '';
        //     position: absolute;
        //     top:90px;
        //     left:90px;  
        //     width: 15px;
        //     height: 15px;
        //     background: #e77a1b;
        //     border-radius: 50%;
        //     opacity: 0.9;
        // }

        
    }

    .donors-cover{
        display: flex;
        justify-content: center;
        margin-bottom: 20px;

        .donors-names{
            display: flex;
            width: 90%;
            justify-content: center;
            text-align: center;

            h4{
                font-size: 2rem;
                    //background: #4f2500;
                    color: #4f2500;
                    padding: 10px;
                    box-shadow: 5px 5px 10px #d56809;
    
                    span{
                        color: #0b16e2;
                        //background: #a410d0;   
                    }

                span:before{
                    content: '';
                    animation: animate infinite 8s;
                }
    
                @keyframes animate{
                    0%{
                        content: 'Providence Human Capital';
                    }
                    15%{
                        content: 'Tanganda Tea';
                    }
                    19%{
                        content: 'JBMS UK';
                    }
                    24%{
                        content: 'Proton Bakeries';
                    }
                    28%{
                        content: 'Bakers Inn Bakeries';
                    }
                    33%{
                        content: 'Windmill';
                    }
                    37%{
                        content: 'Seedco';
                    }
                    42%{
                        content: 'ZIMRA';
                    }
                    46%{
                        content: 'Trinitas Distributors';
                    }
                    51%{
                        content: 'Econet Wireless';
                    }
                    55%{
                        content: 'Nyaradzo Funeral Services';
                    }
                    60%{
                        content: 'Church of Jesus Christ Later Day Saints';
                    }
                    64%{
                        content: 'Christ Embassy';
                    }
                    69%{
                        content: 'TM Pick N Pay Mutoko';
                    }
                    73%{
                        content: 'Mutoko Tiles Centre';
                    }
                    78%{
                        content: 'Nyambodza Hardware';
                    }
                    82%{
                        content: 'Zimbabwe Correctional Services Mutoko';
                    }
                    87%{
                        content: 'Zimbabwe Republic Police';
                    }
                    91%{
                        content: 'Mutoko Rural District Council';
                    }
                    96%{
                        content: 'Department of Social Development (Min Of Social Welfare)';
                    }
                    100%{
                        content: 'Various Prayer Groups';
                    }
                }
            }
            
        }
    }
}

@media screen and (max-width:400px){
    
}

@media screen and (max-width:320px){
    .donation-cover{
        .image-top{
            height: 100px;
            background: url('../images/delivery_food.jpg');
            background-repeat: no-repeat;
            background-size: cover;
            background-position-y: 65%;
    
            h4{
                padding: 60px 10px 10px 10px;
                cursor: pointer;
                bottom: 0 !important;
                font-size: .8rem;
                color: #4f2500;
            }
    
        }
    
        .cover{
            
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            padding: 50px 0;
            gap:40px 60px;
            
    
            .drop{
                position: relative;
                width: 400px;
                height: 400px;
                box-shadow: inset 20px 20px 20px rgba(0,0,0,0.05),
                25px 35px 20px rgba(0,0,0,0.05),
                25px 30px 30px rgba(0,0,0,0.05),
                inset -20px -20px 25px rgba(255,255,255,0.9);
                transition: 0.5s ease-in-out;
                cursor: pointer;
                color:#4f2500;
    
                .content{
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    text-align: center;
                    padding: 40px;
                    gap: 15px;
    
                    h4{
                        position: relative;
                        width: 160px;
                        height: 90px;
                        background: #eff0f4;
                        border-radius: 50%;
                        box-shadow: inset 2px 5px 10px rgba(0,0,0,0.1),
                        inset -2px -5px 10px rgba(255,255,255,1),
                        15px 15px 10px rgba(0,0,0,0.05),
                        15px 10px 15px rgba(0,0,0,0.025);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 1.1rem;
                        color: var(--clr)
                    }
    
                    .logo{
                        img{
                            width: 150px;
                            height: 75px;
                        }
                    }
    
                }
            }
    
            .drop:nth-child(1){
                border-radius: 57% 43% 43% 57% / 43% 43% 57% 70%;
            }
    
            .drop:nth-child(2){
                border-radius: 61% 39% 52% 48% / 44% 59% 41% 56%;
            }
    
            .drop:nth-child(3){
                border-radius: 19% 81% 48% 52% / 72% 21% 79% 28%;
            }
    
            .drop:nth-child(4){
                border-radius: 36% 64% 35% 65% / 72% 21% 79% 28%;
            }
    
            .drop:hover{
                border-radius: 50%;
            }
    
            // .drop::before{
            //     content: '';
            //     position: absolute;
            //     top:50px;
            //     left:65px;  
            //     width: 35px;
            //     height: 35px;
            //     background: #4f2500;
            //     border-radius: 50%;
            //     opacity: 0.9;
            // }
    
            // .drop::after{
            //     content: '';
            //     position: absolute;
            //     top:90px;
            //     left:90px;  
            //     width: 15px;
            //     height: 15px;
            //     background: #e77a1b;
            //     border-radius: 50%;
            //     opacity: 0.9;
            // }
    
            
        }

        .donors-cover{
            display: flex;
            justify-content: center;
    
            .donors-names{
                display: flex;
                width: 90%;
                justify-content: center;
    
                h4{
                    font-size: 2rem;
                        //background: #4f2500;
                        color: #4f2500;
                        padding: 10px;
                        box-shadow: 5px 5px 10px #d56809;
        
                        span{
                            color: #0b16e2;
                            //background: #a410d0;   
                        }
    
                    span:before{
                        content: '';
                        animation: animate infinite 8s;
                    }
        
                    @keyframes animate{
                        0%{
                            content: 'Providence Human Capital';
                        }
                        15%{
                            content: 'Tanganda Tea';
                        }
                        19%{
                            content: 'JBMS UK';
                        }
                        24%{
                            content: 'Proton Bakeries';
                        }
                        28%{
                            content: 'Bakers Inn Bakeries';
                        }
                        33%{
                            content: 'Windmill';
                        }
                        37%{
                            content: 'Seedco';
                        }
                        42%{
                            content: 'ZIMRA';
                        }
                        46%{
                            content: 'Trinitas Distributors';
                        }
                        51%{
                            content: 'Econet Wireless';
                        }
                        55%{
                            content: 'Nyaradzo Funeral Services';
                        }
                        60%{
                            content: 'Church of Jesus Christ Later Day Saints';
                        }
                        64%{
                            content: 'Christ Embassy';
                        }
                        69%{
                            content: 'TM Pick N Pay Mutoko';
                        }
                        73%{
                            content: 'Mutoko Tiles Centre';
                        }
                        78%{
                            content: 'Nyambodza Hardware';
                        }
                        82%{
                            content: 'Zimbabwe Correctional Services Mutoko';
                        }
                        87%{
                            content: 'Zimbabwe Republic Police';
                        }
                        91%{
                            content: 'Mutoko Rural District Council';
                        }
                        96%{
                            content: 'Department of Social Development (Min Of Social Welfare)';
                        }
                        100%{
                            content: 'Various Prayer Groups';
                        }
                    }
                }
                
            }
        }
    } 
}