.footer-cover{
    background: rgb(231,122,27);
    background: linear-gradient(90deg, rgba(231,122,27,1) 0%, rgba(255,255,255,1) 10%, rgba(79,37,0,1) 20%, rgba(79,37,0,1) 50%, rgba(79,37,0,1) 80%, rgba(220,211,204,1) 90%, rgba(231,122,27,1) 100%);
    background-repeat: no-repeat;
    color:#fff;
    margin-top: 2px;

    .footer-image{
        img{
            height: 70px;
            width: 100%;
        }
    }

    .details-cover{
        display: flex;
        flex-direction: column;
        justify-content: center;

        .details{
            text-align: center;

            h4{
                letter-spacing: 1.5px;
            }

            span{
                letter-spacing: 1.5px;
                display: flex;
                align-items: center;
                justify-content: center;
                svg{

                }
            }
        }
    }

    .developer{
        padding: 10px;

        p{
            text-align: center;
            margin: 0;

            a{
                color: #0FDBDB !important;
            }
        }
    }
}

@media screen and (max-width:1000px){

}

@media screen and (max-width:800px){

}

@media screen and (max-width:600px){
    .footer-cover{
        background: rgb(231,122,27);
        background: linear-gradient(90deg, rgba(231,122,27,1) 0%, rgba(255,255,255,1) 10%, rgba(79,37,0,1) 20%, rgba(79,37,0,1) 50%, rgba(79,37,0,1) 80%, rgba(220,211,204,1) 90%, rgba(231,122,27,1) 100%);
        background-repeat: no-repeat;
        color:#fff;
        margin-top: 2px;
    
        .footer-image{
            img{
                height: 70px;
                width: 100%;
            }
        }
    
        .details-cover{
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex-wrap: wrap;
    
            .details{
                font-size: .6rem;
                display: flex;
                flex-direction: column;
                justify-content: center;
                flex-wrap: wrap;
    
                h4{
                    letter-spacing: 1.5px;
                }
    
                span{
                    letter-spacing: 1.5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    svg{
    
                    }
                }
            }
        }
    
        .developer{
            padding: 10px;
    
            p{
                text-align: center;
                margin: 0;
                font-size: .6rem;
    
                a{
                    color: #0FDBDB !important;
                }
            }
        }
    }
}

@media screen and (max-width:400px){
    .footer-cover{
        background: rgb(231,122,27);
        background: linear-gradient(90deg, rgba(231,122,27,1) 0%, rgba(255,255,255,1) 10%, rgba(79,37,0,1) 20%, rgba(79,37,0,1) 50%, rgba(79,37,0,1) 80%, rgba(220,211,204,1) 90%, rgba(231,122,27,1) 100%);
        background-repeat: no-repeat;
        color:#fff;
        margin-top: 2px;
    
        .footer-image{
            img{
                height: 70px;
                width: 100%;
            }
        }
    
        .details-cover{
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex-wrap: wrap;
    
            .details{
                font-size: .6rem;
                display: flex;
                flex-direction: column;
                justify-content: center;
                flex-wrap: wrap;
    
                h4{
                    letter-spacing: 1.5px;
                }
    
                span{
                    letter-spacing: 1.5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    svg{
    
                    }
                }
            }
        }
    
        .developer{
            padding: 10px;
    
            p{
                text-align: center;
                margin: 0;
                font-size: .6rem;
    
                a{
                    color: #0FDBDB !important;
                }
            }
        }
    }
}

@media screen and (max-width:320px){
    .footer-cover{
        background: rgb(231,122,27);
        background: linear-gradient(90deg, rgba(231,122,27,1) 0%, rgba(255,255,255,1) 10%, rgba(79,37,0,1) 20%, rgba(79,37,0,1) 50%, rgba(79,37,0,1) 80%, rgba(220,211,204,1) 90%, rgba(231,122,27,1) 100%);
        background-repeat: no-repeat;
        color:#fff;
        margin-top: 2px;
    
        .footer-image{
            img{
                height: 70px;
                width: 100%;
            }
        }
    
        .details-cover{
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex-wrap: wrap;
    
            .details{
                font-size: .5rem;
                display: flex;
                flex-direction: column;
                justify-content: center;
                flex-wrap: wrap;
    
                h4{
                    letter-spacing: 1.5px;
                }
    
                span{
                    letter-spacing: 1.5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    svg{
    
                    }
                }
            }
        }
    
        .developer{
            padding: 10px;
    
            p{
                text-align: center;
                margin: 0;
                font-size: .5rem;
    
                a{
                    color: #0FDBDB !important;
                }
            }
        }
    }
}