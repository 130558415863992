.about-us-cover{

    display: flex;
    justify-content: center;
    padding: 10px;
    
    .about-us{
        display: flex;
        flex-direction: column;
        width: 90%;
        // background-color: grey;
        

        .logo-area{
            display: flex;
            flex-direction: row;
            justify-content: center;
            padding: 5px;
            img{
                width: 250px;
                height: 250px;
            }
        }

        .history-area{
            margin-top: 10px;
            box-shadow:0px 3px 10px 5px #4f2500;
            padding: 5px;
            border-radius: 5px;

            .heading{
                

                h4{
                    letter-spacing: 1px;
                    text-decoration: underline;
                    font-size: 1.2rem;
                }

                p{
                    padding: 10px;
                    letter-spacing: 2px;
                }

                .image-cover{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;

                    .first-card{
                        margin: 5px;
                        box-shadow:0px 3px 5px 5px #4f2500;
                        transition: 1s;
                        background-color: white;
                        
                        img{
                            height: 200px;
                            width: 200px;
                        }

                    }

                    .first-card:hover{

                        transform: scale(1.3);
                        z-index: 2;

                    }
                }
            }
        }
    }
}