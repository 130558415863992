.contacts-bar-cover{
    background: rgb(79,37,0);
    background: linear-gradient(90deg, rgba(79,37,0,1) 0%, rgba(255,255,255,1) 10%, rgba(79,37,0,1) 20%, rgba(79,37,0,1) 35%, rgba(79,37,0,1) 50%, rgba(79,37,0,1) 65%, rgba(79,37,0,1) 80%, rgba(220,211,204,1) 90%, rgba(79,37,0,1) 100%);
    background-repeat: no-repeat;
    color: #fff;
    padding: 10px;
    position: fixed;
    top: 0;
    width:100%;
    z-index: 10;

    .cover{
        display: flex;
        justify-content: center;

        .facebook{
            display: flex;
            align-items: center;
        }

        .contacts{
            display: flex;
            align-items: center;
        }
    }
}

@media screen and (max-width:1000px){

}

@media screen and (max-width:800px){
    .contacts-bar-cover{
        background: rgb(79,37,0);
        background: linear-gradient(90deg, rgba(79,37,0,1) 0%, rgba(255,255,255,1) 10%, rgba(79,37,0,1) 20%, rgba(79,37,0,1) 35%, rgba(79,37,0,1) 50%, rgba(79,37,0,1) 65%, rgba(79,37,0,1) 80%, rgba(220,211,204,1) 90%, rgba(79,37,0,1) 100%);
        background-repeat: no-repeat;
        color: #fff;
        padding: 10px;
    
        .cover{
            display: flex;
            justify-content: center;
    
            .facebook{
                display: flex;
                align-items: center;
                font-size: .7rem;
            }
    
            .contacts{
                display: flex;
                align-items: center;
                font-size: .7rem;
            }
        }
    }
}

@media screen and (max-width:600px){
    .contacts-bar-cover{
        background: rgb(79,37,0);
        background: linear-gradient(90deg, rgba(79,37,0,1) 0%, rgba(255,255,255,1) 10%, rgba(79,37,0,1) 20%, rgba(79,37,0,1) 35%, rgba(79,37,0,1) 50%, rgba(79,37,0,1) 65%, rgba(79,37,0,1) 80%, rgba(220,211,204,1) 90%, rgba(79,37,0,1) 100%);
        background-repeat: no-repeat;
        color: #fff;
        padding: 10px;
    
        .cover{
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            align-items: center;
            justify-content: center;
    
            .facebook{
                display: flex;
                align-items: center;
                font-size: .6rem;
            }
    
            .contacts{
                display: flex;
                align-items: center;
                font-size: .6rem;
            }
        }
    }
}

@media screen and (max-width:400px){
    
}

@media screen and (max-width:370px){

    .contacts-bar-cover{
        background: rgb(79,37,0);
        background: linear-gradient(90deg, rgba(79,37,0,1) 0%, rgba(255,255,255,1) 10%, rgba(79,37,0,1) 20%, rgba(79,37,0,1) 35%, rgba(79,37,0,1) 50%, rgba(79,37,0,1) 65%, rgba(79,37,0,1) 80%, rgba(220,211,204,1) 90%, rgba(79,37,0,1) 100%);
        background-repeat: no-repeat;
        color: #fff;
        padding: 10px;
        position: fixed;
        top: 0;
        width:100%;
        z-index: 10;
    
        .cover{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
    
            .facebook{
                display: flex;
                align-items: center;
                font-size: .8rem;
            }
    
            .contacts{
                display: flex;
                align-items: center;
                font-size: .8rem;
            }
        }
    }
}